/*###############################################

@Title: Page Contact
@Description:

###############################################*/

.content {
    form {
        .grecaptcha-badge {
            z-index: 100;
        }
    }
    .aussendienst{
        .opc-row,
        .action-row form > div{
            display: flex;
            flex-wrap: wrap;
            &.row-head{
                font-weight: 600;
                margin-top: 2rem;
            }
            .col-md-1{
                flex-basis: 100% / 12 * 1;
            }
            .col-md-2{
                flex-basis: 100% / 12 * 2;
            }
            .col-md-3{
                flex-basis: 100% / 12 * 3;
            }
            .col-md-4{
                flex-basis: 100% / 12 * 4;
            }
            .col-md-6{
                flex-basis: 100% / 12 * 6;
            }
            .col-md-7{
                flex-basis: 100% / 12 * 7;
            }
            .col-md-8{
                flex-basis: 100% / 12 * 8;
            }
            .col-md-12{
                flex-basis: 100%;
            }
        }
        button.action-toggler{
            width: 4rem;
            height: 4rem;
            svg{
                max-width: 100%;
            }
            &.btn-success{
                color: green;
            }
        }
    }
}