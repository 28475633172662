/*###############################################

@Title: Content
@Description:


###############################################*/
html,
body {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

html {
  font-size: 10px;
  max-width: 100%;
  overflow-x: hidden;

  &.offcanvas-menu-open,
  &.search-open {
    overflow: hidden;
  }
}

body {
  font-size: 1.6rem;
  font-family: $_opc-base-font-family;
  font-weight: $_opc-base-font-weight;
  line-height: $_opc-base-line-height;
  background: $gray;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.viewport {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.account-navigation{
  .account-navigation-inner {
    background: $viewport-bg;
    margin-bottom: 63px;
    .opc-navigation{
      position: relative;
      .navigation-level-1{
        display: flex;
        >li{
          border-right: 1px solid $gray;
          &.is-active-parent,
          &.is-active{
            >a{
              background-color: $brand-color-1;
              color: $white;
              border-radius: 4px;
              font-weight: 700;
            }
          }
          >a{
            display: block;
            padding: 10px 40px;
            background-color: $white;
            white-space: nowrap;
          }
        }
      }
      .navigation-level-2{
        margin-top: -4px;
        display: flex;
        flex-wrap: wrap;
        border-radius: 4px;
        background-color: $gray;
        overflow: hidden;
        >li{
          // border-right: 1px solid $gray;
          &.is-active-parent,
          &.is-active{
            >a{
              font-weight: 700;
            }
          }
          >a{
            background-color: $gray;
            display: block;
            padding: 14px 0 10px 40px;
            color: black;
          }
          &:last-child{
            >a{
              padding-right: 40px;
            }
          }
        }
      }
    }
  }
}

.main-wrapper {
	background-color: $canvas-color;
  @extend %mw;
	width: 100%;
  padding-left: 0;
  padding-right: 0;
	@include mq($min-width: map-get($breakpoints, m)) {
  }
	@include mq($min-width: map-get($breakpoints, xl)) {
    padding-left: 0;
    padding-right: 0;
	}

  .main {
    background: $viewport-bg;
    display: flex;
    width: 100%;
    @extend %mw-inner;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
  }

  .main-navigation {
    min-height: calc(100vh - 207px);;
    padding-top: 60px;
    width: 344px;
    flex-shrink: 0;
    transform: translateX(0);
    display: none;
    padding-left: 0;
    position: relative;
    @include mq($min-width: map-get($breakpoints, l)) {
      display: block;
    }
    @include mq($min-width: map-get($breakpoints, xl)) {
      padding-left: 0;
    }
    .opc-navigation[data-op-navtype="simple"] a::before {
      background-image: none;
    }
    .opc-navigation {
      border-right: 1px solid $black;
      position: sticky;
      top: 0;
      li a {
        color: $text-color;
      }
      ul.navigation-level-2{
        li{
          a{
            display: flex;
            // white-space: nowrap;
            font-size: 2rem;
            font-weight: 400;
            padding-left: 0!important;
            &::before{
              content: "";
              width: 63px;
              display: block;
              height: 24px;
              background-size: 24px;
              // background-image: inline-svg($wa-nonfood-svg, $text-color);
              background-position: 30px center;
              background-repeat: no-repeat;
              opacity: 1;
              transition: all 0.2s ease-in-out;
              margin-top: 2px;
              margin-right: 0;
            }
          }
          &[data-icon="wa-milch"]{
            a{
              &::before{
                background-image: inline-svg($wa-milch-svg, $text-color);
              }
            }
          }
          &[data-icon="wa-brot"]{
            a{
              &::before{
                background-image: inline-svg($wa-brot-svg, $text-color);
              }
            }
          }
          &[data-icon="wa-obst-gemuese"]{
            a{
              &::before{
                background-image: inline-svg($wa-obst-gemuese-svg, $text-color);
              }
            }
          }
          &[data-icon="wa-lm-suess"]{
            a{
              &::before{
                background-image: inline-svg($wa-lm-suess-svg, $text-color);
              }
            }
          }
          &[data-icon="wa-lm-salzig"]{
            a{
              &::before{
                background-image: inline-svg($wa-lm-salzig-svg, $text-color);
              }
            }
          }
          &[data-icon="wa-fleisch"]{
            a{
              &::before{
                background-image: inline-svg($wa-fleisch-svg, $text-color);
              }
            }
          }
          &[data-icon="wa-fisch"]{
            a{
              &::before{
                background-image: inline-svg($wa-fisch-svg, $text-color);
              }
            }
          }
          &[data-icon="wa-tk"]{
            a{
              &::before{
                background-image: inline-svg($wa-tk-svg, $text-color);
              }
            }
          }
          &[data-icon="wa-getraenke"]{
            a{
              &::before{
                background-image: inline-svg($wa-getraenke-svg, $text-color);
              }
            }
          }
          &[data-icon="wa-nonfood"],
          &[data-icon="wa-non-food"]{
            a{
              &::before{
                background-image: inline-svg($wa-nonfood-svg, $text-color);
              }
            }
          }
          // &[data-icon="wa-wallis"]{
          //   a{
          //     &::before{
          //       background-image: inline-svg($wa-wallis-svg, $text-color);
          //     }
          //   }
          // }
        }
      }
    }
  }
  .main-content {
    padding-top: 60px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    @include mq($min-width: map-get($breakpoints, m)) {
      max-height: calc(100vh - 207px);
    }
    
    
    @include mq($min-width: map-get($breakpoints, l)) {
      width: calc(100% - 363px);
      margin: 0 auto;
      flex-grow: 1;
    }
    padding-bottom: 20px;
    >h1:first-child{
      margin-top: 0;
    }
  }
  .basket-column{
    width: 40px;
    position: relative;
    z-index: 99;
    margin-right: 0;
    margin-left: 0;
    transform: translateX(0); // noetig, damit mini-basket auf der gleichen Hoehe geoeffnet wird. Geht mit pos:relative nicht.
    background-color: #F9F9F9;
    .opc-basket-info{
      margin: 0;
      padding: 0;
      position: fixed;
      top: 0;
      .opc-button{
        margin: 0;
        padding: 0;
        background-color: $brand-color-1;
        width: 40px;
        height: 40px;
        svg{
          color: $white;
          margin: 0;
          font-size: 34px;
        }
      }
    }
    @include mq($min-width: map-get($breakpoints, m)) {
      width: 63px;
      margin-right: 0;
      margin-left: 0;
      .opc-basket-info {
        .opc-button{
          width: 63px;
          height: 63px;
        }
      }
    }
  }
}

.content {
  //@extend %mw;

  @include clearfix();
  min-height: 400px;

  flex-grow: 1;

  .opc-content-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: #000;
    opacity: 0.2;
  }

  &.aussendienst{
    .action-toggler.btn{
      svg{
          width: 32px;
          height: 16px;
      }
    }
  }
  &.minibasket-isopen {
    padding-right: 0;
    .filter-set-list-item {
      @include mq($min-width: map-get($breakpoints, m)) {
        flex-basis: span(12) !important;
        max-width: span(12) !important;
      }
      @include mq($min-width: map-get($breakpoints, xl)) {
        flex-basis: span(6) !important;
        max-width: span(6) !important;
      }
      @include mq($min-width: map-get($breakpoints, xxl)) {
        flex-basis: span(4) !important;
        max-width: span(4) !important;
      }
    }
    .opc-article-list {
      &>li {
        @include mq($min-width: map-get($breakpoints, s)) {
          flex-basis: span(12) !important;
          max-width: span(12) !important;
        }
        @include mq($min-width: map-get($breakpoints, xl)) {
          flex-basis: span(6) !important;
          max-width: span(6) !important;
        }
        @include mq($min-width: map-get($breakpoints, xxl)) {
          flex-basis: span(4) !important;
          max-width: span(4) !important;
        }
      }
    }
    .opc-category-navigation ul li {
      @include mq($min-width: map-get($breakpoints, m)) {
        flex-basis: span(12) !important;
        max-width: span(12) !important;
      }
      @include mq($min-width: map-get($breakpoints, xl)) {
        flex-basis: span(6) !important;
        max-width: span(6) !important;
      }
      @include mq($min-width: map-get($breakpoints, xxl)) {
        flex-basis: span(4) !important;
        max-width: span(4) !important;
      }
    }
  }
}




.result-view-menu,
.article-list-menu {}

.viewport {
  &.minibasket-isopen {
    .basket-column {
      width: 694px;
      position: sticky;
      top: 0;
      .opc-basket-info {
        position: fixed;
        top: 0;
        right: 0;
        .mini-basket {
          position: fixed;
          min-width: 100vw;
          @include mq($min-width: map-get($breakpoints, s)) {
            min-width: unset;
          }
          &.is-open {
            transform: translateX(0);
            overflow-y: hidden;
          }
        }
      }
    }
  }
}