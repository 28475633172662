/*###############################################

@Title: Sections
@Description:


###############################################*/


// namespace vars
$section-padding: 4rem;
// section layout styles




.section-wrapper {
    @extend %mw;
    max-width: 100%;
    overflow: hidden;
	padding-top: $section-padding / 3;
    padding-bottom: $section-padding / 3;
    padding-left: 30px;
    padding-right: 30px;
    
    @include mq($min-width: map-get($breakpoints, s)) {
        padding-top: $section-padding / 2;
        padding-bottom: $section-padding / 2;
    }

    @include mq($min-width: map-get($breakpoints, m)) {
        padding-top: $section-padding / 1.5;
        padding-bottom: $section-padding / 1.5;
        padding-left: 60px;
        padding-right: 60px;
    }

    @include mq($min-width: map-get($breakpoints, l)) {
        padding-top: $section-padding;
        padding-bottom: $section-padding;
    }
    
    &.no-padding {
        padding-top: 0;
        padding-bottom: 0;
    }
    
	.section-inner {
		@extend %mw-inner;
		@include clearfix();
		
        
        // used for aside with content block
		.section-inner-row {
            
			@include mq($min-width: map-get($breakpoints, m)) {
				@include break;
				@include susy-clearfix;
				margin-left: -$_opc-grid-gutter-width;
				margin-right: -$_opc-grid-gutter-width;
			}
        
            .section-inner-aside {
                width: 100%;
                margin-bottom: 2rem;
                @include mq($min-width: map-get($breakpoints, m)) {
                    margin-bottom: 0;
                    @include span(3);
                }
            }
            .section-inner-content {
                width: 100%;
                @include mq($min-width: map-get($breakpoints, m)) {
                    @include span(9);
                }   
            }
		}
		
		.section-inner-content {
			
		}
	}
}

