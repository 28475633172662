/*###############################################

@Title: Content
@Description:

###############################################*/

// namespace vars
$_header-offcanvas-menu-trigger-color:              #222;                           // color of the hamburger menu icon
$_header-padding:                                   2rem;                             // top and bottom padding of the header
$_header-offcanvas-menu-margin:                     1rem;                             // space between hamburger menu and brand
$_header-spacing:                                   4rem;                             // Spacing between the header sections eg. brand, search, actions

// display config
$always-show-offcanvas-menu-trigger:                'false';                          // define if you want to show the hamburger menu just on mobile or always
$show-offcanvas-menu-trigger:                       map-get($breakpoints, m);         // set breakpoint to define when the hamburger will shown

// header layout styles
.header-wrapper {
	@extend %mw;
	background: $canvas-color; 
	box-shadow: 0 0 3px #ccc;
	position: sticky;
	top: 0;
	z-index: 200;
	padding-left: 0;
	padding-right: 0;

	@include mq($min-width: map-get($breakpoints, m)) {
		position: sticky;
		top: 0;
		box-shadow: none;
	}
	@include mq($min-width: map-get($breakpoints, xl)) {
	}

	.header-inner {
		background-color: $white;
		@extend %mw-inner;
		padding-left: 30px;
		padding-right: 30px;
		//@include clearfix();
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;
		height: 6rem;

		@include mq($min-width: map-get($breakpoints, s)) {
			height: 153px;
		}
		@include mq($min-width: map-get($breakpoints, xl)) {
			padding-left: 63px;
			padding-right: 63px;
		}
	}

	// offcanvas menu
	.offcanvas-menu-trigger {
		margin-right: $_header-offcanvas-menu-margin;
		border: none;
		padding: 0.5rem;
		background: none;
		opacity: 0.6;
		cursor: pointer;
		&:hover {
			opacity: 1;
		}
		span {
			@include burger(25px, 2px, 5px, $_header-offcanvas-menu-trigger-color);
		}
		&.is-active {
			span {
				@include burger-to-cross;
			}
		}
		@if $always-show-offcanvas-menu-trigger !='true' {
			display: block;
			@include mq($min-width: #{$show-offcanvas-menu-trigger}) {
				display: none;
			}
		}
		order: 1;
	}

	// logo
	.brand {
		margin-right: auto;
		order: 2;
		//flex-grow: 1;
		padding: 0 0 0.5rem;
		width: 20%;
		min-width: 120px;
		max-width: 276px;

		a {
			display: block;
		}

		img {
			display: block;
			width: 100%;
		}
		@include mq($min-width: map-get($breakpoints, s)) {
			margin-right: 100px;
		}
		
		@include mq($min-width: map-get($breakpoints, m)) {
			flex-grow: 0;
		}
		
		@include mq($min-width: map-get($breakpoints, xl)) {
			margin-right: auto;
		}
	}

	// header search
	.header-search {
		flex: 1;
		display: flex;
		width: 100%;
		order: 5;
		position: absolute;
		max-width: 882px;
		//margin-top: $_header-spacing / 4;

		&::before {
			content: '';
			display: block;
		}

		@include mq($min-width: map-get($breakpoints, m)) {
			margin-top: 0;
			min-width: 0;
			order: 2;
			position: relative;
			margin: 0 40px;
		}

		input {
			height: 3rem;
			width: 100%;
		}
	}

	// header actions
	.header-actions {
		display: flex;
		order: 4;
		flex-grow: 1;
		justify-content: flex-end;
		margin-left: auto;
		align-items: center;

		@include mq($min-width: map-get($breakpoints, m)) {
			flex-grow:0;
		}

		> .opc-favorite-info,
		> .opc-basket-info,
		> .search-toggle,
		> .account-link {
			margin: 0;
			display: flex;
			> .opc-button {
				margin: 0;
				padding: 0;
				width: 4rem;
				height: 4rem;
				color: #000;
				&:hover {
					color: lighten(#000, 40%);
				}

				> svg {
					font-size: 3.6rem;
					margin: 0;
				}

				@include mq($min-width: map-get($breakpoints, s)) {
					width: 5rem;
				}
			}

			> a {
				color: #000;
				&:hover {
					color: lighten(#000, 40%);
				}
			}
		}

		> .account-link {
			margin-left: 10px;
			&.logged-in{
				margin-left: 20px;
			}
			// @include mq($min-width: map-get($breakpoints, m)) {
			// 	display: none;
			// }
			.account-link-login-indicator {
				width: 1.2rem;
				height: 1.2rem;
				background: $success-color;
				border-radius: 50%;
				position: absolute;
				bottom: 0.4rem;
				right: 0.6rem;
			}
		}

		> .opc-favorite-info {
			display: none;
			@include mq($min-width: map-get($breakpoints, m)) {
				display: flex;
			}
		}

		.opc-basket-info {
			//margin-left: 0.5rem;
			svg {
				font-size: 4rem !important;
			}
		}

    	.search-toggle {
			cursor: pointer;
			width: 4rem;
			height: 4rem;
			padding-left: 0;
			padding-right: 0;
			background: transparent;

			svg {
				font-size: 3.2rem;
				margin: 0;
			}

			@include mq($min-width: map-get($breakpoints, s)) {
				width: 5rem;
			}
			@include mq($min-width: map-get($breakpoints, m)) {

				&.search-toggle {
					display: none;
				}
			}
		}
	}
}

@supports(position: sticky) {
	// sticky behaviour
	.header-wrapper[data-op-sticky="true"] {
		position: sticky;
		top: 0;
		transition: transform 0.2s ease-in-out;
		width: 100%;
		&.sticky-inactive{
			transform: translateY(-7rem);
			transform: translateY(-100%);
		}
	}
}