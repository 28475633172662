.footer-wrapper {
	@extend %mw;
	margin-top: auto;
	overflow: hidden;
	background: $canvas-color;
	font-weight: 300;
	padding-left: 0;
	padding-right: 0;

	.footer-inner {
		@extend %mw-inner;
		display: flex;
		flex-wrap: wrap;
		background: $viewport-bg;
		padding: 0 20px;
		@include mq($min-width: map-get($breakpoints, xs)) {
			padding: 0 30px;
		}
		@include mq($min-width: map-get($breakpoints, xl)) {
			padding: 0 calc(63px + 115px);
			/* SH 14.09.2022 border-right: 63px solid $white; */
		}

		.footer-row {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			padding: 40px 0;
			@include mq($min-width: map-get($breakpoints, xl)) {
				padding: 60px;
			}

			div {
				flex: 50%;
				margin-bottom: 40px;
				@include mq($min-width: map-get($breakpoints, s)) {
					flex: 25%;
				}
			}

			.batch-container {

				.batch {
					display: inline-flex;
					flex-direction: column;
					align-items: center;
					flex-wrap: wrap;
					width: 100%;

					svg{
						height: 90px;
						width: 90px;
						margin-bottom: 20px;
						flex-grow: 1;

						@include mq($min-width: map-get($breakpoints, s)) {
							height: 110px;
							width: 110px;
						}

						@include mq($min-width: map-get($breakpoints, m)) {
							height: 140px;
							width: 140px;
						}

						*{
							transition: all .3s ease-in-out;
						}
					}
					span{
						font-size: 2rem;
						color: $text-color;
						text-align: center;

						@include mq($min-width: map-get($breakpoints, m)) {
							font-size: 3rem;
						}
					}
					&:hover{
						svg{
							*{
								fill: $text-color!important;
							}
						}
					}
				}
			}

			.textbox {
				display: inline-flex;
				flex-direction: column;
				align-items: center;
				flex-wrap: wrap;

				text-align: center;
				line-height: 2.5rem;

				a {
					color: #212121;
					flex-grow: 1;
				}
			}
		}

		.footer-inner-bottom {
			width: 100%;
			color: #212121;
			margin: 70px 0;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			a {
				color: #212121;
				padding: 0 60px;
			}
			.footer-copy {
				text-align: center;
				color: $text-color;
				margin-top: 30px;
				width: 100%;

				a{
					opacity: .6;
					transition: opacity .3s ease-in-out;
					img{
						max-width: 140px;
					}
					&:hover{
						opacity: 1;
					}
				}
			}
		}
	}
}