$cms-topbar-color: #333 !default;
$cms-topbar-bg-color: #fbfbfb !default;
$cms-topbar-border: 1px solid #ccc !default;
$cms-search-border-color: #CCC !default;
$cms-search-bg-color: #FFF !default;
$cms-search-color: #000 !default;
$cms-topbar-button-bg-color: #333 !default;

#opc-cms-fileupload{
    display: none;
}

[data-op-asyn] {
    height: 150px;
    background-color: #EFEFEF;
    animation-name: loadingColor;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    &.finished {
        animation: none;
        background-color: transparent;
        height: auto;
    }

    > span {
        display: none;
    }
}

@keyframes loadingColor {
    0% {
        background-color: #EFEFEF;
    }
    50% {
        background-color: darken(#EFEFEF, 10%);
    }
    100% {
        background-color: #EFEFEF;
    }
}

[data-opc-cms="toggle"] {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 1rem;

    > span {
        margin-left: 1rem;
    }
}



.mp{
    &-hero{
        margin-left: 0!important;
        margin-right: 0!important;
        .column{
            position: relative;
            height: 35rem;
            padding-left: 0!important;
            padding-right: 0!important;
            img{
                max-height: 100%;
                max-width: none;
                // position: absolute;
                // top: 0;
                // right: -30rem;
                display: block;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                height: auto;
                img{
                    max-width: 100%;
                    position: static;
                }
            }
            .text{
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: 128rem;
                margin: auto;
                top: 0;
                padding: 3rem;
                pointer-events: none;
                h3{
                    color: $black;
                    margin-bottom: 13px;
                    font-weight: 700;
                    text-transform: none;
                    font-size: 3.6rem;
                }
                p{
                    color: $black;
                    font-size: 3rem;
                    a{
                        color: $black;
                        text-decoration: none;
                        font-weight: 400;
                    }
                }
            }
            &[contenteditable="true"]{
                .text{
                    position: static;
                    pointer-events: all;
                }
            }
        }
    }
    &-three-teaser{
        margin-left: 0;
        margin-right: 0;
        .column{
            &.third {
                padding: .3rem 0;
                width: 100%!important;
                @include mq($min-width: map-get($breakpoints, m)) {
                    padding: 0 .6rem;
                    width: calc(100% / 3)!important;
                }
            }
            position: relative;
            img{
                width: 100%;
                @include mq($min-width: map-get($breakpoints, m)) {
                    width: auto;
                }
            }
            .text{
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                padding: 3rem;
                @include mq($min-width: map-get($breakpoints, m)) {
                    padding: 4rem;
                }
                h3{
                    color: $brand-color-1;
                    margin-bottom: 13px;
                    font-weight: 400;
                    font-size: 2.8rem!important;
                    @include mq($min-width: map-get($breakpoints, s)) {
                     font-size: 2.8rem!important;
                    }
                    @include mq($min-width: map-get($breakpoints, m)) {
                     font-size: 2.8rem!important;
                    }
                    @include mq($min-width: map-get($breakpoints, xl)) {
                     font-size: 4.6rem!important;
                    }
                }
                p{
                    font-size: 1.8rem!important;
                    @include mq($min-width: map-get($breakpoints, s)) {
                     font-size: 1.8rem!important;
                    }
                    @include mq($min-width: map-get($breakpoints, m)) {
                     font-size: 1.8rem!important;
                    }
                    @include mq($min-width: map-get($breakpoints, xl)) {
                     font-size: 2.4rem!important;
                    }
                    a{
                        color: $gray;
                        text-decoration: underline;
                        font-weight: bold;
                    }
                }
            }
            &[contenteditable="true"]{
                .text{
                    position: static;
                }
            }
        }
    }
}

.opc-cms-content{
    overflow: hidden;
    .row{
        margin-left: 0;
        margin-right: 0;
    }
}