/*###############################################

@Title: Article List
@Description:
This file contains the styling of the article list

###############################################*/

$_opc-article-list-padding : 1.5rem !default;

.article-list-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    .article-list-count {
        flex-grow: 1;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        @include mq($min-width: map-get($breakpoints, s)) {
            width: auto;
        }
    }
    .article-list-sort {
        margin-right: 0;
        @include mq($min-width: map-get($breakpoints, s)) {
            width: auto;
            margin-right: 2rem;
        }
    }
    .article-list-layout {
        .opc-button {
            background: transparent;
            opacity: 0.4;
            &.is-active {
                opacity: 1;
            }
        }
    }
}

.article-list-paging {
    text-align: center;
}




// wrapper element for article list
.opc-article-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-left: -$_opc-grid-gutter-width;
    margin-right: -$_opc-grid-gutter-width;
    >li {
        padding: $_opc-grid-gutter-width;
        flex-basis: span(12);
        max-width: span(12);
        @include mq($min-width: map-get($breakpoints, s)) {
            flex-basis: span(6);
            max-width: span(6);
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            flex-basis: span(4);
            max-width: span(4);
        }
        @include mq($min-width: map-get($breakpoints, l)) {
            flex-basis: span(3);
            max-width: span(3);
        }
        @include mq($min-width: map-get($breakpoints, xl)) {
            flex-basis: span(2);
            max-width: span(2);
        }
    }
    &[data-op-tile-col="4"] {
        >li {
            @include mq($min-width: map-get($breakpoints, s)) {
                flex-basis: span(6);
                max-width: span(6);
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: span(4);
                max-width: span(4);
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                flex-basis: span(3);
                max-width: span(3);
            }
            @include mq($min-width: map-get($breakpoints, xl)) {

            }
        }
    }
    &[data-op-tile-col="3"] {
        >li {
            @include mq($min-width: map-get($breakpoints, s)) {
                flex-basis: span(6);
                max-width: span(6);
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: span(6);
                max-width: span(6);
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                flex-basis: span(4);
                max-width: span(4);
            }
        }
    }
    &[data-op-tile-col="6"] {
        >li {
            flex-basis: span(6);
            max-width: span(6);
            @include mq($min-width: map-get($breakpoints, s)) {
                flex-basis: span(6);
                max-width: span(6);
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: span(4);
                max-width: span(4);
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                flex-basis: span(3);
                max-width: span(3);
            }
            @include mq($min-width: map-get($breakpoints, xl)) {
                flex-basis: span(2);
                max-width: span(2);
            }
        }
    }

    &[data-op-article-list-type="list"] {
        >li {
            width: auto;
            flex-basis: span(12);
            max-width: none;
            padding: $_opc-grid-gutter-width;
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
        }
    }
}

// article list wrapper for sliders
.opc-article-list-slider {
    display: block;
}



// item of article list
.article-list-item {
    padding: $_opc-article-list-padding;
    position: relative;
    border: 1px solid #eee;
    background: #fff;
    &:hover {
        border: 1px solid #ccc;
        z-index: 2;
    }
    .article-list-item-category {
        margin-bottom: 1rem;
        display: none;
        a {
            display: flex;
            align-items: center;
            color: #000;
            &:hover {
                color: lighten(#000, 20%);
            }
            svg {
                margin-right: 1rem;
            }
        }
    }
    .article-list-item-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18rem;
        margin-bottom: 2rem;
        img {
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            flex-shrink: 0;
        }
    }
    .article-list-item-flags {
        position: absolute;
        top: -1rem;
        left: -1rem;
        .new,
        .bestseller {
            display: none;
        }
    }
    .article-list-item-details {
        margin-bottom: 1rem;
        .opc-price {}
        .article-list-item-title {
            @extend .h4;
            font-weight: 600;
            margin-bottom: 0.5rem;
            a {
                color: #000;
                &:hover {
                    color: lighten(#000, 20%);
                }
            }
        }
        .article-list-item-description {
            font-size: 1.4rem;
            font-weight: 400;
        }
    }
    .article-list-item-actions {
        overflow: visible;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: #000;
        .opc-favorite-button {
            //margin-right:0.5rem; margin-bottom:0.5rem;
        }
        .opc-compare-button {
            //margin-bottom:0.5rem;
        }
        .opc-basket-button {
            //width: 100%;

            @include mq($min-width: map-get($breakpoints, m)) {
                width: 4rem;
                padding: 0;
                margin-left: auto;

                svg {
                    margin-right: 0;
                }

                span {
                    //display: none;
                }
            }
        }
        .details-button {
            margin-left: auto;
            width: 100%;
            @include mq($min-width: map-get($breakpoints, m)) { width: auto; }
        }
    }


    // styling for list view
    [data-op-article-list-type="list"] & {

        display: flex;
        flex-wrap: wrap;
        .article-list-item-image {
            margin-bottom: 2rem;
            margin-right: 2rem;
            width: 10rem;
            flex-shrink: 0;
            width: 100%;
            margin-bottom: 2rem;
            display: none;
            img {
                height: auto;
            }
            @include mq($min-width: map-get($breakpoints, s)) {
                height: 10rem;
                width: 10rem;
                margin-bottom: 0;
                display: block;
            }
        }
        .article-list-item-flags {
            top: -1rem;
            right: -1rem;

            &:not(:empty) ~ .article-list-item-details {
                margin-top: 1rem;
                @include mq($min-width: map-get($breakpoints, s)) {
                    margin-top: 0;
                }
            }
        }
        .article-list-item-details {
            margin-bottom: 1rem;
            flex-grow: 1;
            width: 100%;
            @include mq($min-width: map-get($breakpoints, s)) {
                width: 1%;
                margin-bottom: 0;
            }
            .opc-price {}
            .article-list-item-title {
                font-size: 1.8rem;
                font-weight: 600;
                margin-bottom: 0.5rem;
            }
            .article-list-item-description {
                font-size: 1.4rem;
                font-weight: 400;
                display: none;
                @include mq($min-width: map-get($breakpoints, s)) {
                    display: block;
                }
            }
        }
        .article-list-item-actions {
            display: flex;
            justify-content: flex-end;
            flex-wrap: nowrap;
            align-items: flex-end;
            width: 100%;
            flex-wrap: nowrap;
            align-content: flex-start;
            justify-content: space-between;
            z-index: 1;
            .details-button {
                margin-top: auto;
                margin-left: 0;
            }
            .opc-basket-button {
                margin-right: 0;
                margin-left: 0;
                order: 1;
                width: auto;
                flex-grow: 0;
                flex-wrap: nowrap;

                &[data-op-type="single-dim"] {
                    .basket-button-dim-1 {
                        width: 100%;
                        margin-bottom: 1rem;
                        margin-bottom: 0;
                    }
                }
                .opc-button {
                    flex-grow: 0;
                }
            }
            .opc-favorite-button {
                margin-left: 1rem;
                margin-bottom: 0;
                &:first-of-type {
                    margin-left: 0;
                }
            }
            .opc-compare-button {
                margin-left: 1rem;
                margin-bottom: 0;
            }
            .opc-more-button {
                margin-bottom: 0;
                margin-left: 1rem;
                margin-left: 0;
                &:first-child {
                    margin-left: 0;
                }
            }


            @include mq($min-width: map-get($breakpoints, s)) {
                align-items: flex-start;
                justify-content: flex-end;
                flex-wrap: wrap;
                width: auto;
                //width: 20rem;
                flex-wrap: wrap;
                align-content: flex-start;
                .opc-basket-button {
                    margin-right: 0;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    flex-grow: 0;
                    width: 100%;
                    &[data-op-type="single-dim"] {
                        .basket-button-dim-1 {
                            width: auto;
                            margin-bottom: 0;
                        }
                    }
                    .opc-button {
                        flex-grow: 0;
                        svg {
                            display: block;
                        }
                        span {
                            display: none;
                        }
                    }
                }
                .opc-favorite-button,
                .opc-compare-button,
                .opc-more-button {
                    margin-bottom: 1rem;
                    order: unset;
                    margin-left: 1rem;
                }
            }
        }
    }
}
