/*###############################################

@Title: Mainnav
@Description:


###############################################*/

// namespace vars

$mainnav-bg: $superlightgray;
$mainnav-padding: 1rem;


// mainnav layout styles

.opc-navigation[data-op-navtype="simple"] a::before {
	display: none;
}
.main-navigation {
	.opc-navigation{
		.navigation-level-1{
			>li{
				// &.is-active-parent,
				a{}
				&.is-active{
					>a{
						color: $black;
						font-weight: 600;
						display: block;
						// padding-top: 3rem!important;
						padding-bottom: 2rem!important;
					}
				}
			}
			.navigation-level-2{
				>li{
					&.is-active{
						>a{
							color: $black;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
	.products-services-menu{
		.status-subactive{
			display: none;
		}
	}
	.opc-navigation {
		&[data-op-navtype="simple"] {
			a {
				color: $text-color;
				font-size: 1.8rem;

				&:hover {
					color: $text-color;
				}

				&::before {
					display: none;
				}

				&:hover {
					&::before {
						opacity: 1;
					}

				}
			}

			// nav level 1
			.navigation-level-1 {
				>li {
					margin-bottom: 2rem;

					>a {
						padding: 0.7rem 0;
					}

					&:hover {
						// style a tag of hovered element
						background: none;

						>a {}

						// show nav level 2 on hover if available
						>.navigation-level-2 {}
					}
				}
			}

			// nav level 2
			.navigation-level-2 {
				>li {
					&.is-active {
						a {
							color: #CC010A;
						}
					}
					>a {
						color: $black;
						padding-left: 0;
						white-space: nowrap;
						padding-top: 11px;
						padding-bottom: 11px;
					}
					&:first-child{
						>a{
							padding-top: 0;
						}
					}
					&:last-child{
						>a{
							padding-bottom: 0;
						}
					}

					&:hover {
						background: none;

						>a {
							color: $brand-color-1;
							background: none;
						}
					}
				}
			}
			// nav level 2
			.navigation-level-3 {
				>li {
					>a {
						color: $black;
						padding-left: 0;
						white-space: nowrap;
						padding-top: 0.7rem;
						padding-bottom: 0.7rem;
					}

					&.is-active{
						>a{
							font-weight: 600;
						}
					}

					&:hover {
						background: none;

						>a {
							color: $text-color;
							background: none;
						}
					}
				}
			}
		}
	}
}



.mainnav-wrapper {
	@extend %mw;
	// position: relative;
	position: sticky;
	top: 153px;
	z-index: 100;
	display: none;
	background-color: $canvas-color;
	padding-left: 0;
	padding-right: 0;
	
	@include mq($min-width: map-get($breakpoints, m)) {
		display: flex;
		justify-content: space-between;
	}
	
	@include mq($min-width: map-get($breakpoints, xl)) {}
	
	.mainnav-inner {
		background: $mainnav-bg;
		@extend %mw-inner;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		padding-left: 30px;
		padding-right: 30px;
		@include mq($min-width: map-get($breakpoints, xl)) {
			padding-left: 63px;
			padding-right: 63px;
		}
		.mainnav-area-left{
			display: flex;
			align-items: center;
			min-height: 40px;
			>*{
				margin-right: 2rem;
			}
			*{
				font-size: 1.3rem;
				@include mq($min-width: map-get($breakpoints, xl)) {
					font-size: 1.4rem;
				}
				font-weight: 300;
			}
			a{
				color: $text-color;
			}
			>div{
				span{
					&:nth-child(1){
						color: $brand-color-1;
					}
				}
			}

		}
		.mainnav-area-right{
			display: flex;
			align-items: center;
			min-height: 40px;
			justify-content: flex-end;
			>*{
				margin-left: 2rem;
			}
			*{
				font-size: 1.3rem;
				@include mq($min-width: map-get($breakpoints, xl)) {
					font-size: 1.4rem;
				}
				font-weight: 300;
			}
			a{
				color: $text-color;
			}
			a:hover{
				color: $black;
			}
			.opc-language-selection {
				display: flex;
				align-items: center;
				&.is-open{
					background-color: transparent;
					ul{
						background-color: transparent;
						li{
							background-color: transparent;
							a{
								background-color: transparent;
							}
						}
					}
				}
				.language-selection-label {
					display: none;
					padding: 0 .5rem 0 0;
					color: $text-color;
					.current-selection {
						display: none;
					}
				}
				ul {
					position: static;
					display: flex;
					background-color: transparent;
					color: $text-color;
					li {
						border: none !important;
						display: flex;
						align-items: center;
						a {
							padding: 0.5rem 0;
							margin-right: .3rem;
							margin-left: .3rem;
							color: $text-color;
							text-transform: uppercase;
							&:hover{
								color: $brand-color-1;
								background-color: transparent;
							}
						}
						position: relative;
						&:first-child{
							&::after{
								content: "/";
								display: block;
								// width: 0;
								// border-right: 1px solid $text-color;
								// height: 20px;
							}
						}
						&.is-active {
							a {
								opacity: 1;
								color: $brand-color-1;
							}
						}
					}
				}
			}
		}
	}

	.opc-navigation {
		flex-basis: 100%;
		order: 3;
		@include mq($min-width: map-get($breakpoints, l)) {
			order: inherit;
			flex-basis: auto;
		}
		li a {
			color: $text-color;
		}
		li a:hover {
			color: $text-color;
			background: #E5E5E5;
		}
		li.is-active a {
			color: $text-color;
			background: #E5E5E5;
		}
		ul.navigation-level-1{
			display: flex;
			justify-content: center;
			li{
				a{
					display: inline-block;
					white-space: nowrap;
					font-size: 1.6rem;
					font-weight: 300;
					padding: 0 10px 5px;
					@include mq($min-width: map-get($breakpoints, l)) {
						padding: 14px 8px;
					}
					@include mq($min-width: map-get($breakpoints, xl)) {
						font-size: 1.8rem;
						padding: 14px 20px;
						min-height: 54px;
					}
				}
			}
			&::after{
				display: none;
			}
		}
	}
}


@supports(position: sticky) {
	.mainnav-wrapper[data-op-sticky="true"] {
		position: sticky;
		top: 7rem;
		transition: transform 0.2s ease-in-out;
		width: 100%;

		&.sticky-inactive {
			//position: fixed;
			transform: translateY(-119px);
		}
	}
}