/*###############################################

@Title: Page Error
@Description:


###############################################*/

.error-page {
    width: 100%;
    padding: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #eee;
    @include mq($min-width: map-get($breakpoints, m)) {}
    .symbol {
        display: flex;
        font-size: 20rem;
        opacity: 0.2;
        user-select: none;
        span {
            display: block;
            &:nth-of-type(1) {
                transform: rotate(10deg);
                opacity: 0.5;
            }
            &:nth-of-type(2) {
                transform: rotate(-150deg);
            }
            &:nth-of-type(3) {
                transform: rotate(-191deg);
                opacity: .4;
            }
        }
    }
    h1 {}
    .lead {
        margin-bottom: 4rem;
    }
    .opc-button {
        margin: 0 0.5rem 1rem;
    }
    .opc-button-wrapper {
        flex-direction: column;
        @include mq($min-width: 600px) {
            flex-direction: row;
        }
    }
}