/*###############################################

@Title: Page Favorites
@Description:


###############################################*/

.page-favorites,
.page-multi-favorites {
    .favorites-head {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        position: relative;
        .favorites-selection {
            flex-grow: 1;
            .form-field-element{
                padding: 1rem 1rem 1rem 0;
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                max-width: calc(50% - 1.5rem);
            }
            .opc-form-field {
                margin-bottom: 0;
            }
        }
        .favorites-actions {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-left: 1.5rem;
            #rename-form {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                background: transparent;
                z-index: 2;
                .opc-form-field,
                input {
                    width: 100%;
                    border: none;
                }
                .opc-form-field {
                    input {
                        border-bottom: 2px solid #ccc !important;
                        margin: 0 map-get($padding, 'large') map-get($padding, 'small');
                        margin: 0;
                        padding: 0;
                        font-size: 2.8rem;
                        height: 64px;
                    }
                    .form-field-element{
                        padding: 1rem 3rem 1rem 0;
                    }
                    .form-field-note {
                        position: absolute;
                        margin: 0 map-get($padding, 'large') map-get($padding, 'small');
                        .ff-error {
                            display: block;
                        }
                        padding-left: 1rem;
                    }
                    .form-field-error {
                        margin: 0 map-get($padding, 'large') map-get($padding, 'small');
                        display: block;
                        position: absolute;
                        top: 1rem;
                        padding-left: 1rem;
                    }
                }
            }
            .opc-button{
                margin-left: 1.5rem;
                background: $white;
            }
            .opc-button {
                height: 5rem;
                color: black;
            }
        }
        .opc-form-field {
            margin-bottom: 0;
            .headline-select {
                width: 100%;
                border: none !important;
                padding: 1.5rem !important;
                @include mq($min-width: map-get($breakpoints, m)) {
                    margin-left: -1.5rem;
                }
                margin-bottom: 0;
                appearance: none;
                height: auto !important;
                line-height: 1.2 !important;
                background-image: inline-svg($op-icon-angle-down-svg, '#000');
                background-position: calc(100% - 1rem) center;
                background-size: 2rem;
                background-repeat: no-repeat;
                // @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h1, $_opc-font-size-h1-max);
                font-size: 2.8rem;
                &::-ms-expand {
                    display: none;
                }
            }
        }
        h4 {
            font-size: 1.4rem;
            margin-bottom: 1rem;
            padding: 0;
        }
    }

    .favorites-desc {
        margin-bottom: 4rem;
        background: #f8f8f8;
        padding: 2rem;

        h4 {
            margin-bottom: 0.5em;
        }
    }

    .opc-article-list {
        &.favs {
            li {
                display: flex;
                position: relative;
                margin-right: 40px;
                @include mq($min-width: map-get($breakpoints, m)) {
                    margin-right: 0;
                }
                .sorthandle {
                    @include mq($min-width: map-get($breakpoints, m)) {
                        cursor: move;
                        margin-right: 0;
                    }
                    display: none;

                    @include mq($min-width: map-get($breakpoints, m)) {
                        display: block;
                    }

                    order: 2;
                    width: 1.2rem;
                    height: 4rem;
                    background: #eee;
                    margin: .5rem 0;
                    position: relative;
                    flex-grow: 0;
                    cursor: move;
                    transition: background-color 1s ease-out;

                    &::before {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: #eee;
                        transition: background-color 1s ease-out;
                        transform-origin: right;
                        transform: skewY(20deg);
                    }

                    &::after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: #eee;
                        transition: background-color 1s ease-out;
                        transform-origin: right;
                        transform: skewY(-20deg);
                    }
                }

                .sorthandle-mobile-wrapper {
                    margin-bottom: 100%;
                    display: block;
                    position: absolute;
                    left: calc(100% - 15px);
                    z-index: 100;

                    @include mq($min-width: map-get($breakpoints, m)) {
                        display: none;
                    }

                    .sorthandle-mobile {
                        display: flex;
                        background: #eee;
                        color: #333;
                        padding: 1rem;
                        svg{
                            width: 20px;
                        }
                    }
                }

                &:hover {
                    .sorthandle {
                        background-color: $brand-color-2;

                        &::before {
                            background-color: $brand-color-2;
                        }

                        &::after {
                            background-color: $brand-color-2;
                        }
                    }
                }

                .article-list-item {
                    flex-grow: 1;
                    .article-list-item-flags{
                        right: 4rem;
                    }
                    .article-list-item-info {
                        display: flex;
                        flex-wrap: wrap;

                        @include mq($min-width: map-get($breakpoints, m)) {
                            flex-wrap: nowrap;
                        }

                        .article-list-item-description {
                            width: 100%;

                            @include mq($min-width: map-get($breakpoints, m)) {
                                width: 100%;
                            }
                        }

                        dl {
                            display: flex;
                            flex-wrap: wrap;

                            >dt {
                                flex-basis: 100%;
                                font-weight: 600;
                            }

                            >dd {
                                font-weight: normal;
                                flex-basis: 100%;
                                margin-bottom: 1rem;
                            }

                            @include mq($min-width: map-get($breakpoints, s)) {
                                >dt {
                                    flex-basis: 30%;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow-x: hidden;
                                }

                                >dd {
                                    flex-basis: 70%;
                                    margin-bottom: 0;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow-x: hidden;
                                }
                            }
                        }
                    }
                    .article-list-item-actions {

                        .opc-more-button {
                            order: 1;
                            margin: 0;
                            @include mq($min-width: map-get($breakpoints, m)) {
                                order: 0;
                            }
                        }
                    }
                    .basket-list-item-options {
                        >.opc-basket-button{
                            margin-bottom: 20px;
                            .opc-basket-change{
                                label{
                                    font-size: 1.6rem;
                                }
                            }
                            @include mq($min-width: map-get($breakpoints, m)) {
                                .opc-basket-change{
                                    label{
                                        margin: 0 4rem;
                                    }
                                }
                                .order-btn{
                                    margin-left: 4rem;
                                }
                            }
                        }
                        .opc-favorite-button {
                            background-color: white !important;
                            opacity: 1;
                        }
                        .op-icon-favorite-move, .op-icon-favorite-delete {
                            color: black;
                            
                        }

                    }

                    .article-list-item-section {
                        width: 100%;

                        @include mq($min-width: map-get($breakpoints, m)) {
                            width: calc(100% - 12rem);
                        }
                    }
                }

                .opc-message {
                    width: 100%;
                }
            }
        }
    }
}

.basket-list-item-options {
    .opc-basket-button {
        >.opc-basket-button {
            width: auto;
            .basket-button-dim-1, .basket-button-dim-2, .basket-button-dim-3 {
                display: none;
            }
        }
    }
    .opc-basket-button {
        width: auto;
        &[data-op-layout="touch"],
        &[data-op-layout="favTouch"],
        &[data-op-layout="weitaTouch"]{
            .basket-button-dim-1{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-wrap: nowrap;
                label{
                    margin-right: 8px;
                    // SH 10.01.2023 font-size: 12px;
                    color: $darkgray;
                }
            }
        }
        [data-op-qtychange="dec"],
        [data-op-qtychange="inc"]{
            background-color: transparent;
            border: none;
            color: transparent;
            width: 24px;
            height: 24px;
            font-size: 0;
            padding: 0;
            opacity: 1;
            &::before{
                content: "";
                display: inline-block;
                background-image: inline-svg($op-icon-plus, $black);
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                width: 100%;
                height: 100%;
                text-align: center;
                transition: all .3s ease-in-out;
            }
            &:hover,
            &:focus{
                background: none;
            }
            &:hover,
            &[style="pointer-events: none;"]{
                &::before{
                    background-image: inline-svg($op-icon-plus, $brand-color-1);
                }
            }
        }
        [data-op-qtychange="dec"]{
            &::before{
                background-image: inline-svg($op-icon-minus, $black);
            }
            &:hover,
            &:focus{
                background: none;
            }
            &:hover,
            &[style="pointer-events: none;"]{
                &::before{
                    background-image: inline-svg($op-icon-minus, $brand-color-1);
                }
            }
        }
        .basket-button-quantity{
            border-radius: 4px;
            border: 1px solid $gray;
            /* pointer-events: none; */
            margin: 0 10px;
        }
        .order-btn{
            .basket-button-dim-1{
                label,
                input{
                    display: none;
                }
            }
        }
    }
}

.AktArt {
    text-decoration: line-through;
}