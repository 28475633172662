$news-background-color: #EFEFEF !default;

$item-bg-color: $news-background-color !default;
$passive-color: #EF233C !default;

$news-context-bg-color: #333 !default;
$news-context-color: #FFF !default;

$news-passive-color: #CCC !default;

$news-attributes-color: #fc8200 !default;
$news-field-tab-bg: #FFF !default;
$news-tab-hover-color: #CCC !default;
$news-tab-active-color: #fc8200 !default;

$news-darkgray: #707070 !default;
$news-lightgray: #F4F4F4 !default;
$news-orange: #fc8200 !default;

$news-tag-bg-color: #fc8200 !default;
$news-tag-color: #FFF !default;

$news-attr-bg-color: #707070 !default;
$news-attr-color: #FFF !default;

$news-list-bg-color: #EFEFEF !default;

.page-news {
    .opacc-tool-title {
        .opacc-tool-actions {
            [data-news-action="new"] {
                margin-left: auto;
                width: 4rem;
                padding: 0;
                font-size: 4rem;
                line-height: 4rem;
                color: $news-background-color !important;
            }
        }

        [data-news-active="false"] {
            color: $news-passive-color;
        }
    }

    .news-search-wrapper {
        margin-bottom: 2rem;

        .opc-form-field {
            margin-bottom: 0;
        }
        .news-search-filter {
            padding: 1rem;
            background-color: #CCC;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;

                li {
                    margin-right: 1rem;
                    span {
                        cursor: pointer;
                        background: $news-darkgray;
                        color: $news-tag-color;
                        border-radius: 4px;
                        padding: 2px 5px;

                    }
                    &.active {
                        span {
                            background: $news-tag-bg-color;
                        }
                    }
                }
            }
        }
    }
    .news-search-result-wrapper {
        position: relative;
    }

    .news-attributes {
        .news-item-list {
            li {
                border: 2px solid darken($panel-bg-color, 2%);
                border-top: none;
                border-right: none;
                border-left: none;
                background: $panel-bg-color;

                &:hover {
                    border-color: darken($panel-bg-color, 4%);
                    background: darken($panel-bg-color, 1%);
                }

                &.is-passive {
                    border-left: 5px solid $passive-color;
                }
            }
        }
    }

    .news-item-list {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin-bottom: 2rem;
            padding: 2rem;
            border-radius: 5px;
            border: 2px solid darken($panel-bg-color, 5%);
            border-top: none;
            border-right: none;
            border-left: none;
            background: darken($panel-bg-color, 2%);
            cursor: pointer;

            &:hover {
                border-color: darken($panel-bg-color, 7%);
                background: darken($panel-bg-color, 1%);
            }

            &.is-passive {
                border-left: 5px solid $passive-color;
            }

            .news-item-head {
                font-size: 2rem;
                display: flex;
                align-items: center;
                flex-direction: column;
                align-items: flex-start;

                .news-item-actions {
                    margin-left: auto;
                    display: flex;

                    .opc-button {
                        position: relative;

                        &[data-news-active="false"] {
                            color: $news-passive-color;
                        }
                    }
                }

                .news-item-title {
                    display: flex;
                    width: 100%;
                    align-items: center;
                }

                .news-item-info {
                    font-size: small;
                    margin-top: 1rem;
                    color: darken($panel-bg-color, 20%);
                    width: 100%;
                }
            }

            .news-item-body {
                padding-top: 2rem;
            }

            .news-item-prefix {
                display: flex;
                padding-top: 1rem;

                .news-additional {
                    span {
                        font-size: smaller;
                        background: $news-attr-bg-color;
                        color: $news-attr-color;
                        border-radius: 4px;
                        padding: 2px 5px;
                        margin-right: 1rem;
                    }
                }
            }
        }

        .news-tags {
            span {
                font-size: smaller;
                background: $news-tag-bg-color;
                color: $news-tag-color;
                border-radius: 4px;
                padding: 2px 5px;
                margin-right: 1rem;
            }
        }
    }

    .news-contextmenu {
        color: $news-context-color;
        background-color: $news-context-bg-color;
        font-size: smaller;
        position: absolute;
        min-width: 150px;
        left: -999999999px;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 1rem 2rem;
                cursor: pointer;
                background-color: $news-context-bg-color;

                &.separator {
                    padding: 0;
                    height: 1px;
                    background-color: lighten($news-context-bg-color, 5%);
                }

                &:hover {
                    background-color: lighten($news-context-bg-color, 5%);
                }

                &.disabled {
                    pointer-events: none;
                    color: lighten($news-context-bg-color, 20%);
                }
            }
        }
    }

    .news-attributes {
        .news-attributes-item {
            margin-bottom: 2rem;
            padding: 2rem;
            border-radius: 5px;
            border: 2px solid darken($panel-bg-color, 5%);
            border-top: none;
            border-right: none;
            border-left: none;
            background: darken($panel-bg-color, 2%);
            position: relative;

            @include mq($min-width: map-get($breakpoints, m)) {
                margin-bottom: 4rem;
            }
            .attributes-item-header {
                .attribute-item-title {
                    display: flex;
                    h2 {
                        margin-bottom: 0;
                        color: $news-attributes-color;
                        cursor: pointer;
                        width: 100%;
                    }

                    .attribute-item-actions {
                        margin-left: auto;

                        .opc-button {
                            color: $list-item-color;

                            svg {
                                transition: transform 0.1s linear;
                            }
                        }
                    }

                }
                .attribute-item-info {
                    font-size: small;
                    margin-top: 1rem;
                    color: $panel-info-color;
                    width: 100%;
                }
            }
            .attributes-item-body {
                padding-top: 2rem;
                display: none;

                label {
                    font-weight: 600;
                }

                .image-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .attribute-actions-wrapper {
                    display: flex;

                    .opc-button {
                        margin-left: auto;
                    }
                }

                .form-field-tab {
                    margin-bottom: 2rem;

                    label {
                        font-weight: 600;
                        margin-bottom: 1.5rem;
                    }

                    .field-tab-head, .field-tab-body {
                        > ul {
                            margin: 0;
                            padding: 0;
                            list-style: none;

                            display: flex;
                            flex-wrap: wrap;

                            @include mq($min-width: map-get($breakpoints, m)) {
                                flex-wrap: no-wrap;
                            }

                            li {
                                padding: 1rem;
                            }
                        }
                    }

                    .field-tab-head {
                        > ul {
                            li {
                                padding: 2rem 4rem;
                                cursor: pointer;
                                border-bottom: 4px solid transparent;
                                width: 100%;

                                @include mq($min-width: map-get($breakpoints, m)) {
                                    width: auto;
                                }

                                &:hover {
                                    border-color: $news-tab-hover-color;
                                }
                                &.active {
                                    background: $news-field-tab-bg;
                                    border-color: $news-tab-active-color;
                                }
                            }
                        }
                    }

                    .field-tab-body {
                        > ul {
                            li {
                                display: none;
                                background: $news-field-tab-bg;
                                flex-grow: 1;
                                flex-direction: column;

                                .opc-form-field {
                                    // display: flex;
                                    // width: 100%;
                                }

                                &.active {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }

            &.show {
                .attributes-item-header {
                    .attribute-item-actions {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }
                .attributes-item-body {
                    display: block;
                }
            }

            &.news-images {
                &.show {
                    .attributes-item-body {
                        display: flex;
                    }
                }
                .attributes-item-body {
                    margin-left: -1rem;
                    margin-right: -1rem;

                    .news-image-dropzone-wrapper {
                        flex-grow: 1;
                        margin-left: 1rem;
                        margin-right: 1rem;
                        display: flex;
                        flex-direction: column;

                        .news-image-wrapper {
                            flex-grow: 1;
                        }

                        .news-image {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                height: 250px;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

.news-nothing-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    .news-nothing {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        max-width: 500px;
        color: $news-darkgray;

        .news-nothing-title {
            font-size: 3rem;
            padding: 1rem;

            > span {
                font-weight: 600;
            }
        }
        .news-nothing-text {
            text-align: center;
            padding: 1rem;
        }
        > svg {
            font-size: 10rem;
            color: #FFF;
            background-color: $news-orange;
            padding: 1rem;
            border-radius: 50%;
            border-right: 3px solid darken($news-orange, 8%);
            border-bottom: 5px solid darken($news-orange, 8%);

            @include mq($min-width: map-get($breakpoints, m)) {
                font-size: 7vw;
            }
        }
    }
}

.page-news-detail {
    .news-detail-topbar {
        display: flex;
        justify-content: space-between;

        .news-author {
            font-size: larger;
            font-weight: 600;
        }
    }

    .news-detail-tags {
        .news-tags {
            span {
                font-size: smaller;
                background: $news-tag-bg-color;
                color: $news-tag-color;
                border-radius: 4px;
                padding: 2px 5px;
                margin-right: 1rem;
            }
        }
    }
}

.news-list-wrapper {
    margin: 0;
    padding: 0;
    list-style: none;

    .news-list-item {
        .news-item {
            padding: 1rem;
            background-color: $news-list-bg-color;
            border-radius: 4px;

            border-bottom: 4px solid darken($news-list-bg-color, 5%);
        }

        .news-image {
            height: 250px;
            padding: 1rem;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                width: 100%;
                position: relative;
                height: 100%;

                > li {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .news-item-header {
            display: flex;

            .news-date {
                margin-left: auto;
            }
        }

        .news-item-body {
            display: flex;

            .news-info {
                flex-grow: 1;
                width: 100%;
                padding: 1rem;

                .news-title {
                    font-size: large;
                    font-weight: 600;
                }
            }
        }

        .news-tags {
            padding: 1rem;

            span {
                font-size: smaller;
                background: $news-tag-bg-color;
                color: $news-tag-color;
                border-radius: 4px;
                padding: 2px 5px;
                margin-right: 1rem;
            }
        }
    }

    &[data-news-layout="list"] {
        .news-list-item {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }

            .news-item-body {
                .news-image {
                    width: 20%;
                    flex-basis: 20%;
                    display: none;

                    @include mq($min-width: map-get($breakpoints, m)) {
                        display: block;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
    &[data-news-layout="tile"] {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1rem;
        margin-right: -1rem;

        .news-list-item {
            width: 100%;
            flex-basis: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-bottom: 1rem;
            display: flex;

            @include mq($min-width: map-get($breakpoints, m)) {
                width: 25%;
                flex-basis: 25%;
            }

            .news-item {
                flex-grow: 1;
            }

            .news-item-body {
                flex-direction: column;

                .news-image {
                    width: 100%;
                    flex-basis: 100%;

                    img {
                        width: auto;
                        height: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }

    &[data-news-layout="slider"] {
        height: 100%;
    }
}

[data-news-layout="slider"] {
    position: relative;
    .news-slider-thumb {
        padding: 1rem 0 0;
        bottom: 2rem;
        position: absolute;
        width: 100%;
        text-align: center;
        z-index: 9999;
        display: flex;
        justify-content: center;

        button {
            margin: 0 0.3rem;
            opacity: 0.4;
            border-radius: 50%;
            background: $brand-color-1;
            width: 1.2rem;
            height: 1.2rem;
            border: 0;
            outline: none;
            display: block;

            &[aria-selected="true"] {
                opacity: 1;
            }
        }
    }
}