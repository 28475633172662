.news {
    margin-bottom: 3rem;

    button {
        display: none;
    }

    .news-slider {
        .news-slide {
            display: block;
            height: 40vh;
            img {
                width: 100%;
                // height: 100%;
                max-height: 100%;
                // object-fit: cover;
                object-fit: contain;
            }

            &[aria-hidden="false"] {
                &::before {
                    content: ' ';
                    height: .3rem;
                    display: block;
                    position: relative;
                    background-color: $brand-color-1;
                    opacity: .8;
                    animation: load linear 7000ms;

                }
            }
        }
    }
}

@keyframes load {
    0% {
      width: 0%;
    }
  
    100% {
        width: 100%;
    }
}