.opc-article-list {
    &[data-op-tile-col="4"] {
        >li {
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: span(6);
                max-width: span(6);
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                flex-basis: span(6);
                max-width: span(6);
            }
            @include mq($min-width: map-get($breakpoints, xl)) {
                flex-basis: span(4);
                max-width: span(4);
            }
            @include mq($min-width: map-get($breakpoints, xxl)) {
                flex-basis: span(3);
                max-width: span(3);
            }
        }
    }
    &.favs{
        &[data-op-tile-col="4"] {
            >li {
                @include mq($min-width: map-get($breakpoints, m)) {
                    flex-basis: span(12);
                    max-width: span(12);
                }
                @include mq($min-width: map-get($breakpoints, l)) {
                    flex-basis: span(12);
                    max-width: span(12);
                }
                @include mq($min-width: map-get($breakpoints, xl)) {
                    flex-basis: span(12);
                    max-width: span(12);
                }
                // @include mq($min-width: map-get($breakpoints, xxl)) {
                //     flex-basis: span(6);
                //     max-width: span(6);
                // }
                .article-list-item {
                    .article-list-item-thumb {
                        width: 100px;
                        max-width: 100px;
                        margin-right: 1.5rem;
                        border: 1px solid $gray;
                        height: 120px;
                        background: rgb(238,238,238);
                        background: linear-gradient(32deg, rgba(238,238,238,0) 0%, rgba(238,238,238,1) 50%, rgba(238,238,238,0) 100%);
                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            mix-blend-mode: multiply;
                        }
                    }
                }
            }
        }
    }
}


ul[data-scan-app-quantity-str] {
    .article-list-item-buttonarea{
        justify-content: flex-end;
        >div{
            flex-basis: unset;
            width: fit-content;
        }
    }
}

.article-list-item {
    padding: 0;
    border: none;
    background: transparent;
    &:hover{
        border: none;
        z-index: auto;
        .article-list-item-image{
            box-shadow: 0 0 5px $gray;
            img{

                mix-blend-mode: multiply;
            }
        }
    }
    .article-list-item-image{
        border: 1px solid $gray;
        height: 366px;
        background: rgb(238,238,238);
        background: linear-gradient(32deg, rgba(238,238,238,0) 0%, rgba(238,238,238,1) 50%, rgba(238,238,238,0) 100%);
        padding-top: 20px;
        img{
            display: block;
            width: 100%;
            max-height: calc(100% - 80px);
            max-width: calc(100% - 80px);
            mix-blend-mode: multiply;
        }

        .article-list-item-icon {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: absolute;
            bottom: 8px;
            left: 8px;
            z-index: 99;

            svg {
                height: 30px;
                width: 30px;
            }
        }
    }
    .article-list-item-brand{
        a{
            color: $darkgray;
            opacity: .4;
            font-size: 1.4rem;
            font-weight: 600;
        }
    }
    .article-list-item-number{
        a{
            color: $darkgray;
            opacity: .4;
            font-size: 1.4rem;
            font-weight: 600;
        }
    }
    .article-list-item-details {
        .opc-price {
            min-height: 2.4rem;
            margin-bottom: 0.5rem;
        }
        .article-list-item-price{
            font-weight: 700;
            color: $brand-color-1;
            margin-bottom: 5px;
            .AktArt {
                font-weight: 400;
                text-decoration: line-through;
            }
        }
        .article-list-item-title {
            @extend .h4;
            font-size: 1.8rem;
            font-weight: 400;
            // height: 45px;
            overflow: hidden;
        }
        .article-list-item-description {
            overflow: hidden;
            p,
            .is-product-msg {
                height: 4.4rem;
                font-size: 1.4rem;
                overflow: hidden;
                margin-bottom: 0;
                padding: 0.5rem 0;
                line-height: 1.3;
                &:empty {
                    display: none;
                }
            }
            .is-product-msg {
                background: #eee;
                display: block;
                padding: 0.5rem 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                border-radius: 1rem;
                font-size: 1.2rem;
                max-width: 60rem;
            }
        }
        .article-list-item-stock {
            width: 100%;
            display: flex;
            margin-top: 5px;
            margin-bottom: 10px;
            &.stock_green>span::before {
                display: block;
                width: 1.1rem;
                height: 1.1rem;
                border-radius: 1.1rem;
                content: "";
                margin-right: 0.5rem;
                margin-top: 4px;
                background: #25a345;
            }
            &.stock_orange>span::before {
                display: block;
                width: 1.4rem;
                height: 1.4rem;
                border-radius: 1.4rem;
                content: "";
                margin-right: 0.5rem;
                margin-top: 4px;
                background: #ffbd40;
            }
        }
    }

    .article-list-item-buttonarea{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        padding: 15px;
        >div{
            flex-basis: 100%;
        }
        .btn-add{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: 4px;
            .unit-name {
                line-height: 34px;
            }
            .article-action.default{
                background-color: transparent;
                border: none;
                color: transparent;
                width: 34px;
                height: 34px;
                font-size: 0;
                padding: 0;
                opacity: 1;
                &::before{
                    content: "";
                    display: inline-block;
                    background-image: inline-svg($op-icon-plus, $black);
                    background-size: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    transition: all .3s ease-in-out;
                }
                &:hover,
                &:focus{
                    background: none;
                }
                &:hover,
                &[style="pointer-events: none;"]{
                    &::before{
                        background-image: inline-svg($op-icon-plus, $brand-color-1);
                    }
                }
            }
        }
        .btn-change{
            .opc-basket-button {
                width: 100%;
                &[data-op-layout="touch"]{
                    .basket-button-dim-1{
                        position: relative;
                        justify-content: space-between;
                        label{
                            display: block;
                            position: absolute;
                            right: calc(34px + 4px);
                            top: 0;
                            width: auto;
                            height: 34px;
                            margin-bottom: 0;
                            .basket-button-dim-name {
                                display: none;
                            }
                            .basket-button-dim-unit {
                                line-height: 34px;
                            }
                        }
                    }
                }
                [data-op-qtychange="dec"],
                [data-op-qtychange="inc"]{
                    background-color: transparent;
                    border: none;
                    color: transparent;
                    width: 34px;
                    height: 34px;
                    font-size: 0;
                    padding: 0;
                    opacity: 1;
                    &::before{
                        content: "";
                        display: inline-block;
                        background-image: inline-svg($op-icon-plus, $black);
                        background-size: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        transition: all .3s ease-in-out;
                    }
                    &:hover,
                    &:focus{
                        background: none;
                    }
                    &:hover,
                    &[style="pointer-events: none;"]{
                        &::before{
                            background-image: inline-svg($op-icon-plus, $brand-color-1);
                        }
                    }
                }
                [data-op-qtychange="dec"]{
                    &::before{
                        background-image: inline-svg($op-icon-minus, $black);
                    }
                    &:hover,
                    &:focus{
                        background: none;
                    }
                    &:hover,
                    &[style="pointer-events: none;"]{
                        &::before{
                            background-image: inline-svg($op-icon-minus, $brand-color-1);
                        }
                    }
                }
                .basket-button-quantity{
                    border-radius: 4px;
                    border: 1px solid $gray;
                    pointer-events: all;
                }
            }
        }
    }

    // styling action buttons
    .article-list-item-actions {
        overflow: visible;
        // SH 27.03.2023 display: none;
        justify-content: space-between;
        flex-wrap: wrap;
        color: #000;

        .details-button {
            margin-left: auto;
            margin-bottom: 0.5rem;
        }

        .opc-favorite-button { 
            background-color: #eee;
            position: absolute;
            right: 20px;
            bottom: 0;
            &:hover,
            &:focus {
                box-shadow: 1px 3px 3px #ccc;
            }
        }
        .opc-compare-button { 
            background-color: #fff;
            margin-left: 1rem;
            &:hover,
            &:focus {
                box-shadow: 1px 3px 3px #ccc;
            }
        }
        .opc-basket-button {
            width: 4rem;
            padding: 0;
            margin-left: auto;
            display: none;

            svg {
                margin-right: 0;
            }

            span {
                display: none;
            }
            &[data-op-layout="touch"]{
                .basket-button-dim-1{
                    label{
                        display: none;
                    }
                }
            }

            &:hover,
            &:focus {
                box-shadow: 1px 3px 3px #ccc;
            }
        }
        .details-button {
            width: auto;
            @include mq($min-width: map-get($breakpoints, m)) { width: auto; }
        }
        
    }

    [data-op-article-list-type="list"] & {
        padding: 15px;
        border: 1px solid $gray;
        background: rgb(238,238,238);
        background: linear-gradient(32deg, rgba(238,238,238,0) 0%, rgba(238,238,238,1) 50%, rgba(238,238,238,0) 100%);
        .article-list-item-details {

            .article-list-item-title {
                font-size: 1.8rem;
                font-weight: 600;
                margin-bottom: 0.5rem;
                height: auto;
                overflow: hidden;
            }
        }

        .article-list-item-actions {
            overflow: visible;
            display: flex;

            color: #000;

            .details-button {
                margin-left: auto;
                margin-bottom: 0.5rem;
            }

            .opc-favorite-button {
                background-color: #fff;
                margin-right: 0.5rem;

                &:hover,
                &:focus {
                    box-shadow: 1px 3px 3px #ccc;
                }
            }
            .opc-more-button {
                margin-left: 0;
                margin-right: 0.5rem;
            }
            .opc-compare-button { 
                background-color: #fff;
                margin-left: 0;
                margin-right: 0.5rem;
                &:hover,
                &:focus {
                    box-shadow: 1px 3px 3px #ccc;
                }
            }
            .opc-basket-button {
                width: 4rem;
                padding: 0;
                justify-content: center;
    
                svg {
                    margin-right: 0;
                }
    
                span {
                    display: none;
                }
                
                @include mq($min-width: map-get($breakpoints, s)) {
                    
                }
    
                &:hover,
                &:focus {
                    box-shadow: 1px 3px 3px #ccc;
                }
            }
            .details-button {
                width: 100%;
                @include mq($min-width: map-get($breakpoints, m)) { width: auto; }
            }
            
        }

        
    }

    .staffelpreise-wrapper{
        display: none;
        &.is-open{
            display: block;
        }
    }
}


.progressbar-wrapper{
    display: flex;
    justify-content: center;
    padding: 10px 10px 5px;
    .progressbar-track{
        height: 7px;
        width: 100%;
        max-width: 426px;
        background-color: #eee;
        .progressbar-bar{
            height: 100%;
            background-color: #b4b4b4;
        }
    }
}
.article-list-paging {
    margin-top: 60px;
    .opc-segment-navigation{
        .opc-button[data-op-style="secondary"]{
            background-color: $darkgray;
            color: $white;
            padding: 25px 30px;
            font-size: 1.6rem;
        }
    }
}

.opc-catsort{
    label{
        display: none!important;
    }
    #field-sort{
        background-color: transparent;
        border: none;
        font-family: $_opc-base-font-family;
        font-size: 1.6rem;
        font-weight: 300;
    }
}

.article-list-item .article-list-item-flags {
    top: -30px;
    left: 0;
}
.article-head .article-list-item-flags {
    top: -30px;
    left: 10px;
}
.opc-flags {
    &[data-op-layout="icon"] {
        .flag-news {
			background: transparent !important;
            color: $_opc-flags-new-bg;
            z-index: 1;
            margin-right: 0;
        }
        .flag-free1 {
			background: transparent !important;
            color: $_opc-flags-sale-bg;
            z-index: 1;
            margin-right: 0;
        }
        .flag-free2 {
			background: transparent !important;
            color: $_opc-flags-bestseller-bg;
            z-index: 1;
            margin-right: 0;
        }
    }
}


.stock-text {
    font-size: 1.4rem;
}

.opc-category-navigation {
    ul li {
        margin-bottom: 20px !important;
    }
    .category-item {
        .category-item-image {
            align-items: flex-start !important;
            justify-content: right !important;
            background: #fff !important;
            height: 180px !important;
            // height: auto !important;
            .category-item-title {
                position: absolute;
                bottom: 5px;
                left: 15px;
                color: black;
                @include mq($min-width: map-get($breakpoints, s)) {
                    font-size: 16px !important;
                }
                @include mq($min-width: map-get($breakpoints, m)) {
                    font-size: 16px !important;
                }
                @include mq($min-width: map-get($breakpoints, l)) {
                    font-size: 20px !important;
                }
            }
            img, div {
                // flex-basis: 50% !important;
                width: 50% !important;
            }
        }
    }
} 