/*###############################################

@Title: Page Register / Login
@Description:


###############################################*/

.page-start {
    @include mq($max-width: map-get($breakpoints, m)) {
        .carousel-thumb-slides {
            display: none !important;
        }
        .opc-carousel {
            p {
                display: none
            }
        }
        .opc-category-navigation {}
    }
    h1 {}
    .opc-article-list {
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        &[data-op-tile-col="4"] {
            >li {
                flex-basis: span(9);
                max-width: span(9);
                flex-shrink: 0;
                @include mq($min-width: map-get($breakpoints, s)) {
                    flex-basis: span(7);
                    max-width: span(7);
                }
                @include mq($min-width: map-get($breakpoints, m)) {
                    flex-basis: span(6);
                    max-width: span(6);
                }
                @include mq($min-width: map-get($breakpoints, l)) {
                    flex-basis: span(6);
                    max-width: span(6);
                }
                @include mq($min-width: map-get($breakpoints, xl)) {
                    flex-basis: span(4);
                    max-width: span(4);
                }
                @include mq($min-width: map-get($breakpoints, xxl)) {
                    flex-basis: span(3);
                    max-width: span(3);
                }
            }
        }
        .article-list-item-description {
            display: none;
            @include mq($min-width: map-get($breakpoints, m)) {
                display: block;
            }
        }
    }
    .opc-category-navigation {
        .show-all-categories {
            width: 100%;

            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }
    }
}