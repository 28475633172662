/*###############################################

@Title: Page Details
@Description:


###############################################*/

.page-details {
    .article-head {
        //margin-top: 4rem;
        display: flex;
        position: relative;
        align-items: flex-end;
        flex-wrap: wrap;
        @include mq($max-width: map-get($breakpoints, 'medium')) {
        }
        .article-flags {
            padding: 0 $_opc-grid-gutter-width;
            position: absolute;
            left: 0;
            top: 0;
        }
        .article-image {
            flex-basis: 100%;
            padding: $_opc-grid-gutter-width;
            flex-shrink: 0;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            aspect-ratio: 1;
            .opc-asset {
            }
            .opc-asset[data-op-type="gallery"] {
                max-height: auto;
            }
            border: 1px solid $gray;
            background: rgb(238,238,238);
            background: linear-gradient(328deg, rgba(238,238,238,0) 0%, rgba(238,238,238,1) 50%, rgba(238,238,238,0) 100%);
            padding-top: 20px;
            img {
                display: block;
                mix-blend-mode: multiply;
                @include mq($max-width: map-get($breakpoints, s)) {
                    width: 100%;
                }
                max-height: 400px;
                /*
                SH Schlechte Qualitaet Bilder width: 100%;
                SH Schlechte Qualitaet Bilder height: 100%;
                */
                object-fit: contain;
            }
            // s = 640 / m = 800 / l = 1024 / xl = 1400 / xxl = 1680
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: 100%;
                padding-left: 0;
            }
            @include mq($min-width: map-get($breakpoints, xl)) {
                flex-basis: 35%;
            }
            @include mq($min-width: 2048px) {
                flex-basis: 50%;
            }

            .article-list-item-icon {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: absolute;
                bottom: 8px;
                left: 8px;
                z-index: 99;
    
                svg {
                    height: 50px;
                    width: 50px;
                }
            }
        }
        .article-infos {
            flex-basis: 100%;
            padding: $_opc-grid-gutter-width;
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: 100%;
                padding-left: 0;
            }
            @include mq($min-width: map-get($breakpoints, xl)) {
                flex-basis: 60%;
            }
            @include mq($min-width: 2048px) {
                flex-basis: 50%;
            }
            @include mq($min-width: map-get($breakpoints, xl)) {
                padding-left: $_opc-grid-gutter-width * 2;
                padding-bottom: 0;
            }
            .article-list-item-price {
                font-size: 28px;
                font-weight: 600;
                color: $brand-color-1;
                .AktArt {
                    color: black;
                    text-decoration: line-through;
                }
            }
            .opc-stock {
                margin-bottom: 2rem;
            }
            .article-art-nr {
                margin-bottom: 1.5rem;
            }
            .article-desc {
                color: #666;
                margin-bottom: 3rem;
            }
            .article-actions {
                display: none;
                .opc-basket-button {
                    display: none;
                }
            }
            dl.article-spec-infos{
                display: flex;
                flex-wrap: wrap;
                max-width: 500px;
                dt,
                dd{
                    flex-basis: 100%;
                    font-weight: 500;
                    font-size: 1.8rem;
                    color: $brand-color-2;
                    line-height: 2;
                }
                dt{
                    opacity: 60%;
                    @include mq($min-width: map-get($breakpoints, m)) {
                        flex-basis: 45%;
                    }
                }
                dd{
                    @include mq($min-width: map-get($breakpoints, m)) {
                        flex-basis: 55%;
                    }
                }
                small{
                    margin: 20px 0;
                    opacity: 60%;
                }
                .AktArt {
                    color: #fe020d;
                    text-decoration: line-through;
                }
            }
        }
    }

    .article-infos {
        .article-list-item-buttonarea{
            position: relative;
            padding: 15px 0;
            @include mq($min-width: map-get($breakpoints, xl)) {
                padding: 15px 0 0 0;
            }
            .opc-favorite-button{
                height: 52px;
                width: 52px;
                background-color: $lightgray;
                border: none;
                span{
                    display: none;
                }
                svg{
                    margin: 0;
                }
            }
            .btn-add{
                flex-grow: 1;
                padding-left: $_opc-grid-gutter-width * 2;
            }
            .btn-add{
                flex-grow: 1;
                padding-left: $_opc-grid-gutter-width * 2;
                .article-action.default {
                    background-color: $brand-color-1;
                    border: none;
                    color: $white;
                    width: 100%;
                    height: 40px;
                    font-size: 1.4rem;
                    padding: 0 25px;
                    opacity: 1;
                    height: 52px;
                    &::before{
                        display: none;
                    }
                    &:hover {
                        color: $_opc-button-loud-color-hover;
                        background: $_opc-button-loud-bg-hover;
                    }
                }
            }
            .btn-change{
                padding-left: $_opc-grid-gutter-width * 2;
                max-width: 300px;
                .opc-basket-button[data-op-layout="touch"] .basket-button-dim-1 {
                    justify-content: space-evenly;
                    label {
                        display: none;
                    }
                }
            }
            .btn-delete{
                padding-left: $_opc-grid-gutter-width * 2;
            }
        }
    }

    .accordion-wrapper{
        display: flex;
        flex-direction: column;
        color: $text-color;
        @include mq($min-width: map-get($breakpoints, xl)) {
            width: 50%;
        }
        .accordion-head{
            font-size: 2rem;
            font-weight: 400;
            cursor: pointer;
            position: relative;
            padding: 15px 0;
            border-bottom: 1px solid $gray;
            &::after {
                content: '';
                display: block;
                position: absolute;
                right: $_opc-tab-head-arrow-right-padding;
                top: 50%;
                transform: translateY(-50%) rotate(0);
                background-image: inline-svg($op-icon-angle-down-svg, $text-color);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                width: 1.2em;
                height: 1.2em;
            }
            &.is-open{
                color: $brand-color-1;
                &::after {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }
        .accordion-body{
            display: none;
            font-weight: 300;
            padding: 15px 0;

            // @include mq($min-width: map-get($breakpoints, xl)) {
            //     width: 50%;
            // }
            *{
                font-size: 1.8rem;
            }
            &.is-open{
                display: block;
            }
        }
    }

    .section-details-light {
        background: #eee;
        max-width: 100%;
        overflow: hidden;
        .opc-tabs {
            &[data-op-type="accordion"] {
                margin-top: 2rem;
            }
            &[data-op-type="tabs"] {
                .tab-list {
                    border-bottom: none;
                    background: #fff;
                    &::before,
                    &::after {
                        border-bottom: none;
                        background: #fff;
                    }
                    li {
                        border: none;
                        color: #000;
                        &.is-active {
                            color: #000;
                            background: #eee;
                            border-bottom: none;
                            &::after {
                                display: none;
                            }
                        }
                        a {
                            font-size: 1.6rem;
                        }
                    }
                }
                .tab-content-wrapper {
                    margin-bottom: 4rem;
                }
            }
        }
    }
    .section-details-default {
        max-width: 100%;
        overflow: hidden;
        .opc-tabs {
            &[data-op-type="tabs"] {
                .tab-list {
                    border-bottom: none;
                    background-color: transparent;
                    &::before,
                    &::after {
                        border-bottom: none;
                        background: inherit;
                    }
                    li {
                        border: none;
                        color: #000;
                        &.is-active {
                            color: #000;
                            background: transparent;
                            border-bottom: none;
                            &::after {
                                display: none;
                            }
                        }
                        a {
                            font-size: 3rem;
                            padding: 0;
                        }
                    }
                }
                .tab-content-wrapper {
                    margin-bottom: 4rem;
                }
            }
        }
    }
}

.spec-wrapper {
    padding-top: 2rem;
    [data-op-type="accordion"] & {
        padding-top: 0;
    }
    .opc-art-spec {
		display: flex;
		margin-left: -$_opc-grid-gutter-width;
		margin-right: -$_opc-grid-gutter-width;
		width: calc(100% + ($_opc-grid-gutter-width * 2));
        flex-wrap: wrap;

        .art-spec-group {
			padding: $_opc-grid-gutter-width;
			width: 100%;


			@include mq($min-width: map-get($breakpoints, s)) {
				width: 50%;
			}
			@include mq($min-width: map-get($breakpoints, m)) {
				width: 33.3%;
			}
        }
    }
}

.article-liefertage{
    table{
        tr{
            td{
                svg{
                    max-width: 2rem;
                }
            }
        }
    }
}

.staffelpreise-wrapper{
    display: none;
    &.is-open{
        display: block;
    }
}

.opc-favorite-button {
    &.is-active {
        opacity: 0.5;
        background-color: #CC010A !important;
        color: white;
    }
}


