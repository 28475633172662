/*###############################################

@Title: Theme 
@Description:
This file contains all theme relevant files

###############################################*/

// import theme specific node modules or external stuff
@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700,800";
//@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

// import theme overwrites
@import "atoms/atoms";
@import "organisms/organisms";

@import "other/burger";

@import "structure/grid";
@import "structure/topbar";
@import "structure/header";
@import "structure/mainnav";
@import "structure/offcanvasnav";
@import "structure/content";
@import "structure/footer";
@import "structure/section";

@import "pages/druid";
@import "pages/opacc-tool";
@import "pages/news";

// page specific styles
@import "pages/checkout";
@import "pages/contact";
@import "pages/details";
@import "pages/register-login";
@import "pages/favorites";
@import "pages/compare";
@import "pages/basket";
@import "pages/account";
@import "pages/error";
@import "pages/offline";
@import "pages/start";
@import "pages/notsupported";
@import "pages/cms";
@import "pages/toolbar";

* {
	box-sizing: border-box;
}


.opc-input:disabled, .opc-input:read-only, .opc-textarea:disabled, .opc-textarea:read-only {
	opacity: 1;
}

.opc-item-list .list-item:hover {
	margin-left: -0.5rem;
	margin-right: -0.5rem;
	width: calc(100% + 1rem);
}

.hidden {
	display: none !important;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Open Sans";
}

.dimmer {
	position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    z-index: 10000000;
	display: flex;
	justify-content: center;
	align-items: center;
	// &::after {
	// 	content: "";
	// 	width: 50px;
	// 	height: 50px;
	// 	border-radius: 50px;
	// 	border: 5px solid $brand-color-2;
	// 	border-top-color: $brand-color-1;
	// 	animation: loading 2s linear infinite;
	// }
}

// @keyframes loading {
// 	0% {
// 	  	transform: rotate(0deg);
// 	}
// 	100% {
// 	  	transform: rotate(360deg);
// 	}
// }