/*###############################################

@Title: Grid
@Description:
Custm Grid patterns

###############################################*/

[data-op-col="12-4-4"] {
    width: 100%;
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(6);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(4);
    }
}
[data-op-col="12-6-6"] {
    width: 100%;
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(6);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(6);
    }
}