.subcategory-wrapper {
    flex-basis: 100% !important;
    width: 100% !important;
    max-width: 100% !important;

    @include mq($min-width: map-get($breakpoints, s)) {
        flex-basis: 50% !important;
        width: 50% !important;
        max-width: 50% !important;
    }
    @include mq($min-width: map-get($breakpoints, xl)) {
        flex-basis: 33.33333% !important;
        width: 33.33333% !important;
        max-width: 33.33333% !important;
    }

    .category-item.subcategory {
        display: flex;
        position: relative;

        .category-item-image {
            width: 100%;
            img {
                position: absolute;
                overflow: hidden;
                object-fit: cover;
                width: 100% !important;
                height: 100% !important;
                // opacity: 0.2;
            }
            &:hover {
                opacity: 0.8;
            }
        }

        .category-item-links {
            // width: 100% !important;
            position: absolute;
            left: 16px;
            bottom: 16px;
            font-weight: 600;
            right: 0;

            .category-item-title {
                font-weight: 600;
                position: unset;
            }

            .category-item-text {
                font-size: $_opc-font-size-m;
                a {
                    margin-bottom: 0.2rem;
                    display: block;
                    color: $_opc-form-field-color;

                    &:hover {
                        padding-left: 0.5rem;
                        transition: ease-in-out 0.2s;
                    }
                }
            }
        }
    }
}
