.scanner-overlay {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 16px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.8);
    z-index: 999;

    &--visible {
        display: flex;
    }

    &__loading-container {
        width: 80%;

        @include mq($min-width: map-get($breakpoints, s)) {
            width: 400px;
        }
        height: 30px;
        border: 1px solid #666;
        border-radius: 5px;
        position: relative;
    }

    &__loading-bar {
        height: 100%;
        background-color: rgba($brand-color-1, 0.7);
        border-radius: 5px;
        width: 0%;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.3s ease;
    }

    &__loading-text {
        color: rgba(255, 255, 255, 0.8);
        font-family: "Open Sans";
        font-weight: 400;
        line-height: 1.25;
        font-size: 18px;
        text-align: center;
        max-width: 80%;

        @include mq($min-width: map-get($breakpoints, s)) {
            max-width: unset;
        }
    }

    &__actions-area {
        display: none;
        column-gap: 16px;

        &--visible {
            display: flex;
        }
    }
}