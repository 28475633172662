/*###############################################

@Title: Topbar
@Description:


###############################################*/


// namespace vars

$_topbar-padding: 			1rem;
$_topbar-group-divider-color:	#eee;


#topbar-login-form {
	display: none;
  }  

// topbar layout styles

.topbar-wrapper {
	@extend %mw;
	
	background-color: $canvas-color;
	padding-top: $_topbar-padding;
	padding: 0;
      
    display: none;
    
    @include mq($min-width: map-get($breakpoints, m)) {
        display: block;
    }
	 
	
	.topbar-inner {
		background-color: $white;
		@extend %mw-inner;
		font-size: 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-left: 63px;
		padding-right: 63px;
	}
	
	.topbar-group {
		display: inline-block;
		text-align: left;
		padding-left: 1rem;
		margin-left: 1rem;
		position: relative;
		line-height: 1.6;
		font-size: 1.4rem;
		
		&:first-child {
			&::before {
				display: none;
			} 
		}
		
		&::before {
			content: '';
			display: block;
			width: 1px;
			background: $_topbar-group-divider-color;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
		}
		
		select {
			font-weight: bold;
			border: none;
		}
		
		a {
			//color: inherit;
			text-decoration: none;
			color: #000; 
			&:hover {
				color: lighten(#000, 40%);
			}
		}
		.count {
			&::before {
				content: '(';
			}
			&::after {
				content: ')';
			}
		}
	}
	.admin-panel {
		
	}
}

.account-link{
	position: relative;
	>a{
		// font-weight: 300;
	}
	.opc-navigation{
		margin-bottom: 1rem;
		a{
			font-weight: 300;
			line-height: 1.2;
			font-size: 1.6rem;
		}
	}
	.account-link-user-menu{
		opacity: 0;
		visibility: hidden;
		position: absolute;
		z-index: -100;
		top: calc(100% + 2rem);
		right: 0;
		min-width: 32rem;
		max-width: 52rem;
		min-height: 20rem;
		background: $brand-color-1;
		color: $white;
		// border-radius: .5rem;
		box-shadow: 0 0 10px rgba(#000, .3);
		transition: all .3s ease-in;
		padding: 3rem;
		width: auto;
		@include mq($min-width: map-get($breakpoints, m)) {
			width: 52rem;
		}
		a{
			color: $white;
			&:hover{
				color: $black;
			}
		}
		h3{
			a{
				font-size: 2rem;
				white-space: nowrap;
			}
			margin-bottom: 2rem;
			margin-left: -1rem;
		}
		svg{
			color: $white;
			font-size: 4rem;
		}
		.logout-btn{
			float: right;
			padding: 0;
			a{
				padding-right: 4rem;
			}
			svg{
				margin-left: -4rem;
			}
			&:hover{
				a{
					font-weight: 500;
					color: $black;
				}
				svg{
					color: $black;
				}
			}
		}
		.navigation-level-1{
			display: flex;
			flex-wrap: wrap;
			>li{
				flex-basis: 100%;
				>a{
					font-weight: 500;
					margin-top: 20px;
					&::before{
						content: "";
						display: none;
					}
				}
				.navigation-level-2{
					>li{
						>a{
							font-weight: 300;
							padding-left: 0;
							&::before{
								content: '';
								background-image: inline-svg($op-icon-angle-right-svg, $white);
								margin-right: 0.5rem;
								width: 1em;
								height: 1em;
								display: inline-block;
								background-position: center;
								background-size: 100%;
								background-repeat: no-repeat;
								opacity: 1;
							}
							&:hover{
								background: transparent;
								font-weight: 500;
							}
						}
					}
				}
			}
			@include mq($min-width: map-get($breakpoints, m)) {
				>li{
					flex-basis: calc(50% - 1.5rem);
				}
			}
		}
	}
	&:hover{
	// &.is-open{
		.account-link-user-menu{
			opacity: 1;
			visibility: visible;
			z-index: 100001;
		}
	}
}
