.opc-basket-info {
    >.opc-button {
        font-size: $_opc-basket-button-font-size;
        border: none;
        background: none;
        margin-left: 0.5rem;
        cursor: pointer;
        position: relative;
        padding: 0 0.5rem;
        svg {
            font-size: 4rem;
        }
        &:hover {}
        .basket-info-count {
            width: 1.8rem;
            height: 1.8rem;
            display: block;
            background: transparent;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            bottom: auto;
            right: auto;
            left: auto;
            transform: translateY(-4px);
            font-size: 1.1rem;
            font-weight: 600;
            text-align: center;
            line-height: 1.8rem;
            color: $white;
            box-shadow: none;
            &:empty {
                display: none;
            }
        }
    } 
    
    .mini-basket{
        position: sticky;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: all .2s linear, opacity .3s linear;
        display: block;
        background: $white;
        // max-height: calc(100vh);
        max-height: calc(100vh - 68px);
        top: 0;
        font-weight: 300;
        z-index: 400;
        width: 100%;
        max-width: 100%;
        min-width: 0;
        width: 360px;
        pointer-events: none;
        overflow: hidden;
        transform: translateX(500px);
		@include mq($min-width: map-get($breakpoints, m)) {
            box-shadow: 0 0 10px rgba(#000,.16);
            width: 50rem;
            height: calc(100vh - 162px);
		}
		@include mq($min-width: map-get($breakpoints, l)) {
            height: calc(100vh - 216px);
		}
        @include mq($min-width: map-get($breakpoints, xl)) {
            height: calc(100vh - 216px);
        }
        &.is-open{
            opacity: 1;
            max-width: 100%;
            pointer-events: all;
            transform: translateX(0);
            overflow-y: auto;
            .mini-basket-body,
            .mini-basket-footer{
                opacity: 1;
            }
        }
        .mini-basket-head{
            font-weight: 300;
            font-size: 2.2rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            &::after{
                content: "";
    			background-image: inline-svg($op-icon-cross-svg, '$offcanvas-color');
                display: block;
                width: 3rem;
                height: 3rem;
                float: right;
            }
            >span{
                display: block;
                width: calc(100% - 40px);
                font-size: 2.4rem;
            }
        }
        .mini-basket-body{
            // max-height: calc(100vh - 186px);
            max-height: calc(100% - 220px);
            opacity: 0;
            ul.basket-item-list{
                li.basket-item{
                    transition: all .3s ease-in-out;
                    max-height: 200px;
                    align-items: inherit;
                    .basket-item-thumb{
                        width: 100px;
                        max-width: 100px;
                        margin-right: 1.5rem;
                        border: 1px solid $gray;
                        height: 120px;
                        background: rgb(238,238,238);
                        background: linear-gradient(32deg, rgba(238,238,238,0) 0%, rgba(238,238,238,1) 50%, rgba(238,238,238,0) 100%);
                        img{
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            mix-blend-mode: multiply;
                        }
                        a{
                            display: flex;
                            align-items: flex-end;
                            height: 100%;
                            padding-bottom: 10px;
                        }
                    }
                    .basket-item-info{
                        padding-right: 2rem;
                        display: flex;
                        flex-wrap: wrap;
                        .basket-item-price-wrapper{
                            flex-basis: 100%;
                            .basket-item-currency,
                            .basket-item-price{
                                font-size: 1.6rem;
                                font-weight: 500;
                                color: $brand-color-1;
                            }
                        }
                        .basket-item-title{
                            padding-right: 0;
                            // display: inline;
                            a{
                                font-weight: 400;
                                font-size: 1.8rem;
                            }
                            flex-basis: 100%;
                        }
                        .basket-item-subtitle{
                            margin-bottom: .5rem;
                            a{
                                font-weight: 300;
                                font-size: 1.8rem;
                            }
                            flex-basis: 100%;
                        }
                        .basket-item-article-number{
                            color: $brand-color-3;
                            display: none;
                        }
                        .basket-item-quantity{
                            &::before{
                                display: block;
                                content: " ";
                            }
                        }
                        .basket-item-info-top{
                            display: flex;
                            flex-direction: column;
                        }
                        .basket-item-info-bottom{
                            flex-basis: 100%;
                            margin-top: auto;
                            display: flex;
                            justify-content: flex-end;
                        }
                        .basket-item-unit{}

                        .opc-basket-button {
                            width: auto;
                            &[data-op-layout="touch"]{
                                .basket-button-dim-1{
                                    justify-content: flex-end;
                                    align-items: center;
                                    flex-wrap: nowrap;
                                    label{
                                        margin-right: 8px;
                                        font-size: 12px;
                                        color: $darkgray;
                                    }
                                }
                            }
                            [data-op-qtychange="dec"],
                            [data-op-qtychange="inc"]{
                                background-color: transparent;
                                border: none;
                                color: transparent;
                                width: 24px;
                                height: 24px;
                                font-size: 0;
                                padding: 0;
                                opacity: 1;
                                &::before{
                                    content: "";
                                    display: inline-block;
                                    background-image: inline-svg($op-icon-plus, $black);
                                    background-size: 100%;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    width: 100%;
                                    height: 100%;
                                    text-align: center;
                                    transition: all .3s ease-in-out;
                                }
                                &:hover,
                                &:focus{
                                    background: none;
                                }
                                &:hover,
                                &[style="pointer-events: none;"]{
                                    &::before{
                                        background-image: inline-svg($op-icon-plus, $brand-color-1);
                                    }
                                }
                            }
                            [data-op-qtychange="dec"]{
                                &::before{
                                    background-image: inline-svg($op-icon-minus, $black);
                                }
                                &:hover,
                                &:focus{
                                    background: none;
                                }
                                &:hover,
                                &[style="pointer-events: none;"]{
                                    &::before{
                                        background-image: inline-svg($op-icon-minus, $brand-color-1);
                                    }
                                }
                            }
                            .basket-button-quantity{
                                border-radius: 4px;
                                border: 1px solid $gray;
                                // pointer-events: none;
                                margin: 0 10px;
                            }
                        }
                    }
                    &.is-hidden{
                        opacity: 0;
                        max-height: 0;
                        overflow: hidden;
                    }
                }
            }
        }
        .mini-basket-footer{
            margin-top: 1rem;
            padding: 2rem;
            opacity: 0;
            .mini-basket-footer-total{
                font-weight: 500;
                font-size: 1.6rem;
                b{
                    font-weight: 300;
                }
            }
            .mini-basket-footer-actions{
                display: flex;
                justify-content: space-between;
                .opc-button{
                    height: 52px;
                    width: 52px;
                    svg{
                        font-size: 2.4rem;
                    }
                    &.favorite{
                        background-color: $lightgray;
                        >span{
                            display: none;
                        }
                        svg{
                            color: $text-color;
                        }
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                    &.checkout{
                        width: auto;
                        margin-left: 3rem;
                        flex-grow: 1;
                        span{
                            color: $white;
                        }
                        svg{
                            display: none;
                        }
                        &:hover {
                            color: $_opc-button-loud-color-hover;
                            background: $_opc-button-loud-bg-hover;
                        }
                    }
                }
            }
            .mini-basket-footer-prices{
                font-size: 2rem;
                dl{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    dt{
                        flex-basis: 50%;
                    }
                    dd{
                        flex-basis: 50%;
                        text-align: right;
                        font-weight: 400;
                    }
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.checkout-buttons {
    display: flex;
    justify-content: space-between;
}
.opc-basket-summary {
    .basket-summary-head {
        font-weight: bold;
        padding: 0.5rem 0;
        border-bottom: 1px solid #999;
    }
    .basket-summary-body {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 2rem;
    }
    .basket-summary-head,
    .basket-summary-item {
        display: flex;
        .basket-summary-item-title {
            flex-grow: 1;
            width: 60%;
            padding: 0 1rem 0 1rem;
            color: #000;
        }
        .basket-summary-item-dimvariant {
            width: 20%;
            flex-shrink: 0;
            padding: 0 1rem 0 0;
            text-align: right;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 10rem;
                text-align: right;
            }
        }
        .basket-summary-item-count {
            width: 20%;
            flex-shrink: 0;
            padding: 0 1rem 0 0;
            text-align: right;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 10rem;
                text-align: right;
            }
        }
        .basket-summary-item-price {
            width: 20%;
            flex-shrink: 0;
            text-align: right;
            padding: 0 1rem 0 0;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 14rem;
            }
        }
    }
    .basket-summary-item {
        padding: 1rem 0;
        border-bottom: 1px solid #eee;
        &:hover {
            background: #eee;
        }
    }
    .basket-summary-footer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 2rem;
        background: #eee;
        padding: 1rem;
        .summary-footer-line dl {
            display: flex;
            margin: 0;
            padding: 0.5rem 0;
            dt {}
            dd {
                width: 12rem;
                text-align: right;
            }
        }
    }
    #agb-verification .form-field-element {
        display: flex;
        justify-content: flex-end;
    }
}