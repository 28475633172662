.opc-search {

    position: relative;
    width: 100%;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20000;

    visibility: hidden;
    opacity: 0;
    z-index: -100;

    &.is-open {
        visibility: visible;
        opacity: 1;
        z-index: 20000;
    }

    .search-toggle {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
        display: none;
    }

    .opc-search-bg {
        background: rgba(#fff, 0.98);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: block;
    }

    .opc-search-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        padding: 2rem;

    }


    @include mq($min-width: map-get($breakpoints, m)) {
        position: relative;
        width: 100%;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        background: none;
        visibility: visible;
        opacity: 1;
        z-index: 20000;


        .opc-search-bg,
        .opc-search-toggle {
            display: none;

        }

        .opc-search-content {
            position: relative;
            padding: 0;
        }



    }


    .opc-search-content {

        .opc-button[data-op-action="close"] {

            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }

        .opc-search-query {
            border: none;
            border-radius: 0px;
            display: flex;
            align-items: center;
            padding: 5px 0;
            background-color: $lightgray;
            input.search-query {
                border: none;
                height: 50px;
                font-size: 1.6rem;
                font-weight: 300;
                padding-left: 15px;
                padding-right: 10px;
                background-color: transparent;

                &:focus {
                    outline: none;
                }
            }

            select.search-filter {
                border: none;
                border-left: 1px solid #ccc;
                padding: 0 20px 0 10px;
                height: 30px;
                background-color: transparent;
                font-weight: 300;
                font-size: 1.6rem;
                
                &:focus {
                    outline: none;
                }
            }
            
            .search-btn {
                background-color: transparent;
                border: none;
                height: 32px;
                width: auto;
                flex-shrink: 0;
                color: $text-color;
                padding-left: 15px;
                padding-right: 15px;
                cursor: pointer;
                svg{
                    font-size: 32px;
                }
            }
        }

        .opc-search-results {
            display: none;
            padding: 0;
            position: relative;
            top: 0;

            @include mq($min-width: map-get($breakpoints, m)) {
                position: absolute;
                top: 100%;
            }

            .opc-directsearch-message {
                height: 4rem;
                position: relative;
                z-index: 2;
                background: #eee;
                margin: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .searchTerm {
                background: $lightgray;
            }

        }


    }



    input {
        width: 100%;
    }
}



.column-section-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        border-bottom: 1px solid #eee;

        a {
            display: block;
            padding: 1.5rem 0;

            &:hover {
                background: #eee;
            }

            >span {
                display: block;
            }



        }
    }

    &[data-op-type="article-image"] {
        .list-item-image {
            width: 8rem;
            margin-right: 1rem;
        }

        .list-item-info {
            width: calc(100% - 9rem);
        }

        .list-item-description {
            max-width: 100%;
        }


        a {
            display: flex;
            width: 100%;
            padding: 1rem 0;

            .image {
                //background: #ccc;
                margin-right: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .info {

                .name {
                    display: block;
                    font-weight: 600;
                    margin-bottom: 0.2em;
                }

                .additional-name {
                    display: block;
                    font-size: 1.2rem;
                    opacity: 0.8;
                    margin-bottom: 0.2em;
                }

                .description {
                    display: block;
                }

                .price {}



            }


        }




    }

    &[data-op-type="simple"] {
        a {
            position: relative;
        }

        .CatStruct-Name {}

        .CatStruct-Name~.Amount {
            display: none;
        }

        .CatStruct-Number {
            display: none;
        }
    }

    .moreItems {
        a {
            padding: 1rem;
            font-weight: bold;

            span {
                margin: 0;
                font-size: 1.2rem;
            }
        }
    }
}