/*###############################################

@Title: Page Register / Login
@Description:


###############################################*/

.page-register-login {
    h1 {
        position: absolute;
        visibility: hidden;
        height: 0;
        top: 0;
        overflow: hidden;
        pointer-events: none;
    }
    .opc-button.fullwidth {
        width: 100%;
        text-align: center;

        @include mq($min-width: map-get($breakpoints, 'm')) {
            width: auto;
        }
    }
    .content-box {
        .content-box-body {
            .content-box-slide-content {
                display: block;
                height: 0;
                opacity: 0;
                transition: all 0.4s ease-in-out;
                overflow: hidden;

                &.is-open  {
                    opacity: 1;
                }

                &.is-open + button  {
                    display: none;
                }
            }
        }
    }
}

.form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

#register-form-addr,
#register-form-contact {
    flex-wrap: wrap;
    display: flex;
    
    @include mq($min-width: map-get($breakpoints, 's')) {
        width: calc(100% + 2rem);
        margin-left: -1rem;
        margin-right: -1rem;
    }

    .opc-form-field {
        width: 100%; 

        @include mq($min-width: map-get($breakpoints, 's')) {
            width: calc(50% - 2rem);
            margin-right: 1rem;
            margin-left: 1rem; 
        }

        @include mq($min-width: map-get($breakpoints, 'm')) {
            width: calc(100% + 2rem);
        }
        @include mq($min-width: map-get($breakpoints, 'l')) {
            width: calc(50% - 2rem);
            margin-right: 1rem;
            margin-left: 1rem; 
        }
        

    }
    h3 {
        width: 100%;
        @include mq($min-width: map-get($breakpoints, 's')) { padding-left: 1rem; }
    }
    .opc-button {
        @include mq($min-width: map-get($breakpoints, 's')) {  margin-left: 1rem; }
    }

    .form-footer .opc-form-field {
        width: 100%;
    }
}